.campaign-details {
    padding: 2.5rem 3rem;

    .title {
        color: #080019;
        font-size: 1.28rem;
        font-weight: 500;
    }

    .text {
        background: #F1F0F5;
        color: rgba(0, 0, 0, 0.45);
        font-size: 0.95rem;
        padding: 0.5rem 1rem;
        overflow: hidden;
        text-overflow: ellipsis;

        .type {
            text-transform: capitalize;
        }
    }
    .text.name,
    .text.record-source {
        text-transform: capitalize;
    }
}