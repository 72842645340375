.navbar {
    padding: 0 1.5rem;
    z-index: 1001;
    // background: linear-gradient(90deg, #0e1035 0, #0e1035 220px, #020429 221px, #020429 100% );
    background: linear-gradient(90deg, #080019 0, #080019 45%, #0C041D 100%);
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .navbar-ham {
        display: none;
        color: white;
        font-size: 1.71rem;
    }

    .logo {
        flex: 0.9 0.1;

        img {
            max-height: 60px;
            padding-bottom: 0.25rem;
        }
    }

    .ant-menu-dark {
        background: transparent;
        flex: 0.1 1;
        display: flex;
        align-items: center;

        .ant-menu-item {
            padding: 0;
            // padding-top: 0.4rem;

            svg * {
                stroke: transparent;
                fill: rgba(255, 255, 255, 0.65);
            }

            .user-name {
                text-transform: capitalize;
            }

            .user-status-tag {
                margin-left: 0.25rem;
            }

            .anticon {
                font-size: 1.5rem;
                padding-top: 1rem;
            }

            .ant-avatar {
                background-color: #FC4747;
                border: 2px solid white;
                box-sizing: content-box;
                text-transform: capitalize;
                font-size: 1.57rem;
            }
            .avatar-details {
                display: inline-block;
                margin: 0 0.75rem;
                font-size: 0.93rem;
                line-height: 20px;
                vertical-align: middle;
                text-transform: capitalize;

                .domain-name {
                    font-size: 0.78rem;
                }
            }
        }
        .ant-menu-item:nth-of-type(2) {
            padding-right: 0.5rem;
            // padding-top: 0.75rem;
        }
        .ant-menu-item:nth-of-type(4) {
            padding-right: 1.5rem;
        }
        .ant-menu-item:nth-of-type(6) {
            // padding-top: 0rem;
        }
        .ant-menu-item-selected,
        .ant-menu-item-active,
        .ant-menu.ant-menu-dark .ant-menu-item-selected,
        .ant-menu.ant-menu-dark .ant-menu-item-active,
        .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected,
        .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-active {
            background: #00162F;
            svg * {
                fill: rgba(255, 255, 255, 1);
            }
        }
    }
}
.navbar-user-dropdown {
    width: 14.28rem;
    padding: 2.2px;
    p {
        margin-bottom: 0;
    }
    .current-account-details {
        font-size: 1rem;
        display: flex;
        flex-direction: row;
        .account-name {
            margin-right: 0.71rem;
            display: inline-block;
            overflow: hidden;
            text-overflow: ellipsis;
            flex: 999;
        }
        .ant-tag {
            border-radius: 9px;
            margin-right: 0;
        }
    }
    .switch-account-label {
        font-size: 0.85rem;
        margin-top: 0.71rem;
    }
    .ant-dropdown-menu-item {
        font-size: 1rem;
        padding: 0.85rem 1.414rem;
        overflow: hidden;
        text-overflow: ellipsis;
        .anticon {
            margin-left: 8px;
            display: none;
        }
    }
    .ant-dropdown-menu-item:hover {
        background: #E5DDFF;
        .anticon {
            display: inline-block;
        }
    }
}
@media (max-width: 768px) {
    .header .ant-menu {
        display: none;
    }
    .header.ant-layout-header .navbar-ham {
        display: block;
        float: right;
    }
}
