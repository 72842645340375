.campaign-card-container {
    .name {
        font-size: 1.14rem;
        color: #080019;
        font-weight: 500;
    }
    .ant-card {
        .ant-card-body {
            padding: 0 1.5rem;

            .ant-tabs-bar {
                margin-bottom: 0;

                .ant-tabs-nav-container,
                .ant-tabs-extra-content {
                    padding: 1.25rem 2rem;
                    padding-bottom: 0;
                }

                .ant-tabs-extra-content {
                    .buttons-div {
                        flex: 1;
                        display: flex;
                        justify-content: flex-start;
                        flex-flow: row wrap;

                        .ant-btn.ant-btn-danger {
                            margin-right: 0.5rem;
                            background: #23202C;
                            border-color: #23202C !important;
                            color: #FFFFFF;
                        }
                        .ant-btn.ant-btn-danger:active,
                        .ant-btn.ant-btn-danger:hover {
                            background: rgba(35, 32, 44, 0.9);
                            color: #FFFFFF;
                        }

                        .ant-btn.ant-btn-primary {
                            background: #36C626;
                            border-color: #36C626 !important;
                            color: #FFFFFF;
                        }
                        .ant-btn.ant-btn-primary:active,
                        .ant-btn.ant-btn-primary:hover {
                            background: rgba(54, 198, 38, 0.9);
                            color: #FFFFFF;
                        }
                    }
                }
            }

            .card-length-changer {
                color: #8348FF;
                font-size: 1rem;
                cursor: pointer;
                text-align: center;
                padding-top: 1rem;
                font-weight: 500;
            }
        }

        .tag {
            border-radius: 20px;
            border: none;
            padding: 0rem 1rem;
            margin-right: 2rem;
            font-size: 0.86rem;
        }

        .ant-card-head {
            padding: 0.5rem 0.5rem;

            .ant-card-head-title {
                padding: 0;

                .card-title-div {
                    display: flex;
                    flex-flow: row wrap;
                    justify-content: space-between;
                    background: #FAFAFA;
                    padding: 1rem 2rem;
                    align-items: center;

                    .name-div {
                        flex: 0.2;

                        .name {
                            margin-bottom: 0;
                            color: #23202C;
                            font-size: 1.28rem;
                            text-transform: capitalize;
                        }
                    }

                    .created-at-div {
                        flex: 0.25;

                        .created-at {
                            color: rgba(0, 0, 0, 0.65);
                            font-size: 1rem;
                            margin-bottom: 0;
                        }
                    }

                    .stats-div {
                        flex: 0.35;
                        display: flex;
                        justify-content: space-evenly;
                        text-align: center;

                        .stats {
                            font-size: 1rem;
                            color: #080019;
                            .anticon {
                                svg {
                                    height: 1.43rem;
                                    width: 1.43rem;
                                }
                            }
                            .normal-stat {display: block}
                            .hover-stat {display: none}
                        }
                        .stats:hover {
                            .normal-stat {display: none}
                            .hover-stat {display: block}
                        }
                    }

                    .actions-div {
                        flex: 0.1;
                        font-size: 1rem;

                        .ant-dropdown-link {
                            color: #595959;
                        }
                        .ant-dropdown-link:hover,
                        .ant-dropdown-link:active {
                            color: #000;
                        }
                    }
                }
            }
        }
    }
    .ant-alert.ant-alert-warning {
        position: absolute;
        top: 10px;
        left: 33%;
        width: 35%;
        z-index: 9;
        border: 1px solid #FDE58F;
        box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.16);
    }
}
.campaign-card-actions-menu {
    margin-right: 1rem;

    .ant-dropdown-menu-item {
        padding: 0;

        a {
            font-size: 1rem;
            padding: 1rem 5rem 0.75rem 2rem;

            .ant-switch {
                vertical-align: baseline;
                margin-left: 0.5rem;
            }
            .ant-switch-checked {
                background: #36C626;
            }
        }
    }
}