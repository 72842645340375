.alerts-row {
  display: flex;
  margin-bottom: 50px;
  .alerts-row-column {
    flex: 1;
    .alert-card {
      padding: 25px 30px;
      border: 1px solid #707070;
      border-radius: 3px;
      height: 100%;
      cursor: pointer;
      transition-duration: 0.3s;
      p {
        margin-bottom: 0;
      }
      .alert-card-title {
        position: relative;
        font-size: 16px;
        line-height: 22px;
        padding-bottom: 21px;
        font-weight: 500;
        color: #151515;
        .right {
          position: absolute;
          right: 0
        }
      }
      .alert-card-description {
        margin-bottom: 21px;
        color: #595959;
      }
    }.active {
      border: 1px solid #36C626;
    }
    .alert-card.has-error {
      border: 1px solid #FF2F2F;
      box-shadow: 0 0 8px #FF2F2F !important;
      .ant-alert {
        margin: 10px 0;
        width: 100%;
      }
      .alert-card-title {
        color: #FF2F2F;
      }
    }
    .coming-soon {
      pointer-events: none;
      filter: grayscale(1);
      overflow: hidden;
      .ant-switch {
        display: none;
      }
    }
    .coming-soon:after{
      position: absolute;
      content: "Coming Soon";
      font-weight: bold;
      top: -15px;
      right: -55px;
      transform-origin: 0 0;
      transform: rotate(45deg);
      background: #02032a;
      color: white;
      padding: 0 20px;
    }
    .alert-card:hover {
      box-shadow: 0 2px 6px rgba(0,0,0,0.25);
    }
  }
}