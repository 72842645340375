html, body {
    min-width: 1280px;
    height: 100%;
}
body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#root, .content-body {
    height: 100%
}
html {
    font-size: 12px;
}
@media screen and (min-width: 320px) {
    html {
        font-size: calc(12px + 4 * ((100vw - 320px) / 961));
    }
}
@media screen and (min-width: 1281px) {
    html {
        font-size: 16px;
    }
}
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a, a:active, a:focus, a:hover {
    color: #8348FF;
    text-decoration: none !important;
}
.ant-btn-primary {
    background: #8348FF;
    border-color: #8348FF;
}
.ant-btn-primary:hover {
    background: #A561FF;
    border-color: #A561FF;
}
::-moz-selection {
    background: #8348FF;
}
::selection {
    background: #8348FF;
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #8348FF;
}
.ant-checkbox-checked .ant-checkbox-inner {
    background: #8348FF;
    border-color: #8348FF;
}
.ant-checkbox-indeterminate .ant-checkbox-inner:after {
    background: #8348FF;
}
.ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
    border-color: #A561FF;
}
.ant-input:focus {
    border-color: #A561FF;
    box-shadow: 0 0 0 2px rgba(165, 97, 255, 0.2);
}
.ant-dropdown-menu-item:hover, .ant-dropdown-menu-submenu-title:hover {
    background: #E3D6FD;
}

.ant-card {
    border-radius: 4px;
    box-shadow: 0rem 0.21rem 0.42rem 0 rgba(0, 0, 0, 0.16);
}
.ant-tabs-tab {
    font-size: 1.14rem;
}
.ant-tabs-tab-active,
.ant-tabs-tab:active,
.ant-tabs-tab:hover {
    color: #080019 !important;
}
.ant-tabs-ink-bar {
    background: #080019;
}

.ant-pagination-item-active,
.ant-pagination-item:hover,
.ant-pagination-item:active {
    background: #EB5703 !important;
    border-color: #EB5703 !important;
    outline: none !important;
}
.ant-pagination-item a {
    color: rgba(0, 0, 0, 0.65);
}
.ant-pagination-item-active a,
.ant-pagination-item:hover a,
.ant-pagination-item:active a {
    color: #FFFFFF !important;
}

.delete-button,
.delete-button:hover,
.delete-button:active {
    background: #FFFFFF !important;
    color: #F84B3D !important;
    border-color: #F84B3D !important;
    outline: none !important;
    font-size: 0.86rem;
}

.ant-skeleton,
.ant-alert-error {
    width: 90%;
    margin: auto;
}

.ant-modal-mask {
    background: rgba(2, 4, 41, 0.9);
}
.login-page-container {
  width: 100%;
  height: 100%;
  background-image: url(/static/media/login_background.6f5e1683.svg);
  background-color: #F7F7FC;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed; }

.login-container {
  width: 536px;
  max-width: 100%;
  height: 100%;
  margin: auto;
  padding: 100px 15px 0 15px; }
  .login-container .logo {
    margin-bottom: 18px;
    max-width: 200px;
    max-height: 100px; }
  .login-container .tagline {
    color: rgba(0, 0, 0, 0.45);
    margin: 0 0 42px 0; }
  .login-container .ant-tabs-bar {
    border: 0;
    text-align: center; }
  .login-container .login-form-forgot, .login-container .login-form-signup-here {
    float: right; }
  .login-container .login-form-button {
    width: 100%;
    margin: 25px 0; }
  .login-container .register-tag-line {
    margin: 15px 0 10px 0; }
  .login-container .login-page-footer {
    position: -webkit-sticky;
    position: sticky;
    top: calc(100% - 55px); }
    .login-container .login-page-footer .footer-links {
      list-style-type: none;
      padding: 0; }
      .login-container .login-page-footer .footer-links li {
        display: inline-block;
        margin: 0 20px; }
        .login-container .login-page-footer .footer-links li a {
          color: rgba(0, 0, 0, 0.45); }
        .login-container .login-page-footer .footer-links li a:hover {
          color: black; }

.signup-form-container {
  min-height: 100%;
  background: url(/static/media/setUpbackground.5fcee0c7.png);
  background-position-y: top;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 10rem;
  position: relative; }
  .signup-form-container svg.logo {
    position: absolute;
    top: 1.5rem;
    left: 1.5rem;
    max-width: 100%; }
  .signup-form-container .signup-card {
    width: 700px;
    max-width: 100%;
    margin: auto;
    box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, 0.2);
    border-radius: 0; }
    .signup-form-container .signup-card .ant-card-body {
      padding: 2.57rem; }
      .signup-form-container .signup-card .ant-card-body .title {
        text-align: center;
        font-size: 2rem;
        margin-bottom: 0;
        color: #3a3543;
        font-weight: 500; }
      .signup-form-container .signup-card .ant-card-body .sub-title {
        text-align: center;
        font-size: 1.143rem;
        color: #000;
        font-weight: lighter; }
      .signup-form-container .signup-card .ant-card-body .signup-form {
        padding-top: 2.143rem; }
        .signup-form-container .signup-card .ant-card-body .signup-form .flex {
          display: -webkit-flex;
          display: flex;
          -webkit-justify-content: space-between;
                  justify-content: space-between;
          -webkit-align-items: flex-start;
                  align-items: flex-start; }
          .signup-form-container .signup-card .ant-card-body .signup-form .flex .ant-form-item,
          .signup-form-container .signup-card .ant-card-body .signup-form .flex .form-button,
          .signup-form-container .signup-card .ant-card-body .signup-form .flex .disclaimer {
            width: 48%;
            margin-bottom: 0.36rem; }
        .signup-form-container .signup-card .ant-card-body .signup-form .ant-form-item {
          display: block;
          margin-top: 0.71rem;
          margin-bottom: 1.07rem; }
          .signup-form-container .signup-card .ant-card-body .signup-form .ant-form-item input {
            border: 2px solid #cdcdcc;
            border-radius: 4px;
            min-height: 40px; }
          .signup-form-container .signup-card .ant-card-body .signup-form .ant-form-item input:hover,
          .signup-form-container .signup-card .ant-card-body .signup-form .ant-form-item input:active,
          .signup-form-container .signup-card .ant-card-body .signup-form .ant-form-item input:focus,
          .signup-form-container .signup-card .ant-card-body .signup-form .ant-form-item input:focus-within {
            border-color: #4d4d4d;
            box-shadow: 0 0 0 2px rgba(77, 77, 77, 0); }
          .signup-form-container .signup-card .ant-card-body .signup-form .ant-form-item .ant-checkbox-wrapper {
            display: block; }
            .signup-form-container .signup-card .ant-card-body .signup-form .ant-form-item .ant-checkbox-wrapper span {
              color: #4d4d4d;
              font-size: 1rem;
              font-weight: lighter; }
        .signup-form-container .signup-card .ant-card-body .signup-form .has-error input {
          border-color: #ff1c1c !important; }
        .signup-form-container .signup-card .ant-card-body .signup-form .label {
          font-size: 1rem;
          font-weight: lighter;
          color: #808080; }
        .signup-form-container .signup-card .ant-card-body .signup-form .checkbox-label {
          color: #868686;
          font-size: 1rem;
          font-weight: 400;
          font-style: italic;
          display: block; }
        .signup-form-container .signup-card .ant-card-body .signup-form .disclaimer {
          font-weight: 500; }
          .signup-form-container .signup-card .ant-card-body .signup-form .disclaimer a {
            color: #ff806f !important; }
        .signup-form-container .signup-card .ant-card-body .signup-form .form-button {
          font-size: 1.86rem;
          background: #00db6e;
          border-color: #00db6e;
          outline: none;
          padding: 16px 10%;
          text-transform: capitalize;
          height: 5.5rem;
          max-height: 100%;
          font-weight: 600;
          border-radius: 10px; }
      .signup-form-container .signup-card .ant-card-body .bottom-link {
        color: #868686;
        font-size: 0.86rem;
        font-weight: 500;
        text-align: center;
        margin: 1rem 0 0 0; }
        .signup-form-container .signup-card .ant-card-body .bottom-link a {
          color: #ff806f !important; }
  .signup-form-container .onboarding-footer {
    position: absolute;
    bottom: 1rem;
    left: 50%;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0); }

.signup-password-popover-content h5 {
  color: #535353;
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 0.1rem; }

.signup-password-popover-content p {
  color: #868686;
  font-size: 1rem;
  margin-bottom: 0.1rem; }
  .signup-password-popover-content p svg {
    width: 1rem;
    height: 1rem;
    vertical-align: text-bottom; }

.onboarding-footer {
  position: -webkit-sticky;
  position: sticky;
  top: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column; }
  .onboarding-footer div {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    width: 11.786rem; }
    .onboarding-footer div a {
      color: rgba(0, 0, 0, 0.45) !important;
      text-decoration: none !important;
      font-size: 1rem;
      font-weight: 400; }
  .onboarding-footer .copyright-text {
    color: rgba(0, 0, 0, 0.45);
    font-size: 0.857rem;
    font-weight: 400; }

.forgot-password-form {
  height: 100%; }
  .forgot-password-form .sub-heading {
    font-size: 1.43rem;
    text-align: center;
    padding-left: 5rem;
    padding-right: 5rem; }
  .forgot-password-form .form-button {
    display: inline-block;
    background: #1890ff;
    color: #FFFFFF;
    font-size: 1rem;
    text-align: center;
    width: 50%;
    border: none;
    outline: none; }
  .forgot-password-form .anticon.anticon-left-circle {
    font-size: 2rem; }

.ant-layout {
  background: #F7F7F7; }
  .ant-layout .ant-layout-has-sider {
    height: 100%; }

.ant-card {
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
  border-color: #edf2f9;
  border-radius: 0.3rem; }

.navbar {
  padding: 0 1.5rem;
  z-index: 1001;
  background: -webkit-linear-gradient(left, #080019 0, #080019 45%, #0C041D 100%);
  background: linear-gradient(90deg, #080019 0, #080019 45%, #0C041D 100%);
  font-weight: 600;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center; }
  .navbar .navbar-ham {
    display: none;
    color: white;
    font-size: 1.71rem; }
  .navbar .logo {
    -webkit-flex: 0.9 0.1;
            flex: 0.9 0.1; }
    .navbar .logo img {
      max-height: 60px;
      padding-bottom: 0.25rem; }
  .navbar .ant-menu-dark {
    background: transparent;
    -webkit-flex: 0.1 1;
            flex: 0.1 1;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center; }
    .navbar .ant-menu-dark .ant-menu-item {
      padding: 0; }
      .navbar .ant-menu-dark .ant-menu-item svg * {
        stroke: transparent;
        fill: rgba(255, 255, 255, 0.65); }
      .navbar .ant-menu-dark .ant-menu-item .user-name {
        text-transform: capitalize; }
      .navbar .ant-menu-dark .ant-menu-item .user-status-tag {
        margin-left: 0.25rem; }
      .navbar .ant-menu-dark .ant-menu-item .anticon {
        font-size: 1.5rem;
        padding-top: 1rem; }
      .navbar .ant-menu-dark .ant-menu-item .ant-avatar {
        background-color: #FC4747;
        border: 2px solid white;
        box-sizing: content-box;
        text-transform: capitalize;
        font-size: 1.57rem; }
      .navbar .ant-menu-dark .ant-menu-item .avatar-details {
        display: inline-block;
        margin: 0 0.75rem;
        font-size: 0.93rem;
        line-height: 20px;
        vertical-align: middle;
        text-transform: capitalize; }
        .navbar .ant-menu-dark .ant-menu-item .avatar-details .domain-name {
          font-size: 0.78rem; }
    .navbar .ant-menu-dark .ant-menu-item:nth-of-type(2) {
      padding-right: 0.5rem; }
    .navbar .ant-menu-dark .ant-menu-item:nth-of-type(4) {
      padding-right: 1.5rem; }
    .navbar .ant-menu-dark .ant-menu-item-selected,
    .navbar .ant-menu-dark .ant-menu-item-active,
    .navbar .ant-menu-dark .ant-menu.ant-menu-dark .ant-menu-item-selected,
    .navbar .ant-menu-dark .ant-menu.ant-menu-dark .ant-menu-item-active,
    .navbar .ant-menu-dark .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected,
    .navbar .ant-menu-dark .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-active {
      background: #00162F; }
      .navbar .ant-menu-dark .ant-menu-item-selected svg *,
      .navbar .ant-menu-dark .ant-menu-item-active svg *,
      .navbar .ant-menu-dark .ant-menu.ant-menu-dark .ant-menu-item-selected svg *,
      .navbar .ant-menu-dark .ant-menu.ant-menu-dark .ant-menu-item-active svg *,
      .navbar .ant-menu-dark .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected svg *,
      .navbar .ant-menu-dark .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-active svg * {
        fill: white; }

.navbar-user-dropdown {
  width: 14.28rem;
  padding: 2.2px; }
  .navbar-user-dropdown p {
    margin-bottom: 0; }
  .navbar-user-dropdown .current-account-details {
    font-size: 1rem;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row; }
    .navbar-user-dropdown .current-account-details .account-name {
      margin-right: 0.71rem;
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-flex: 999 1;
              flex: 999 1; }
    .navbar-user-dropdown .current-account-details .ant-tag {
      border-radius: 9px;
      margin-right: 0; }
  .navbar-user-dropdown .switch-account-label {
    font-size: 0.85rem;
    margin-top: 0.71rem; }
  .navbar-user-dropdown .ant-dropdown-menu-item {
    font-size: 1rem;
    padding: 0.85rem 1.414rem;
    overflow: hidden;
    text-overflow: ellipsis; }
    .navbar-user-dropdown .ant-dropdown-menu-item .anticon {
      margin-left: 8px;
      display: none; }
  .navbar-user-dropdown .ant-dropdown-menu-item:hover {
    background: #E5DDFF; }
    .navbar-user-dropdown .ant-dropdown-menu-item:hover .anticon {
      display: inline-block; }

@media (max-width: 768px) {
  .header .ant-menu {
    display: none; }
  .header.ant-layout-header .navbar-ham {
    display: block;
    float: right; } }

.sidebar {
  background: #F7F7F7;
  padding-top: 0.5rem;
  border-right: 1px solid #979797; }
  .sidebar .ant-menu {
    height: '100%';
    border-right: 0;
    background: transparent; }
    .sidebar .ant-menu a {
      color: rgba(8, 0, 25, 0.65);
      font-weight: 500;
      vertical-align: middle; }
      .sidebar .ant-menu a .anticon {
        vertical-align: middle; }
      .sidebar .ant-menu a .text {
        vertical-align: middle; }
      .sidebar .ant-menu a svg {
        font-size: 1.25rem; }
      .sidebar .ant-menu a svg * {
        stroke: transparent;
        fill: #919eab; }
    .sidebar .ant-menu a:hover {
      color: rgba(8, 0, 25, 0.65);
      font-weight: 500; }
      .sidebar .ant-menu a:hover svg * {
        fill: #637381; }
    .sidebar .ant-menu a.active {
      color: #0e0f36;
      font-weight: 600; }
      .sidebar .ant-menu a.active svg * {
        fill: #0e0f36; }
    .sidebar .ant-menu .ant-menu-item,
    .sidebar .ant-menu .ant-menu-submenu {
      font-size: 1rem;
      margin: 0; }
    .sidebar .ant-menu .ant-menu-item:last-of-type,
    .sidebar .ant-menu .ant-menu-submenu:last-of-type {
      margin-bottom: 0 !important; }
    .sidebar .ant-menu .ant-menu-submenu-title {
      font-size: 1rem; }
      .sidebar .ant-menu .ant-menu-submenu-title .ant-menu-submenu-arrow {
        display: none; }
    .sidebar .ant-menu .ant-menu-sub .ant-menu-item {
      font-size: 0.9rem;
      line-height: 0.9rem;
      height: 1.5rem;
      margin-bottom: 5px; }
      .sidebar .ant-menu .ant-menu-sub .ant-menu-item a {
        line-height: 0.8rem; }
    .sidebar .ant-menu .ant-menu-submenu-title:hover {
      color: #141414; }
      .sidebar .ant-menu .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:after, .sidebar .ant-menu .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:before {
        background-image: -webkit-linear-gradient(left, #141414, #141414);
        background-image: linear-gradient(to right, #141414, #141414); }
    .sidebar .ant-menu .ant-menu-selected,
    .sidebar .ant-menu .ant-menu-active,
    .sidebar .ant-menu .ant-menu-item-selected,
    .sidebar .ant-menu .ant-menu-item-active,
    .sidebar .ant-menu .ant-menu-submenu-open {
      background-color: transparent !important; }
      .sidebar .ant-menu .ant-menu-selected .ant-menu-submenu-title,
      .sidebar .ant-menu .ant-menu-active .ant-menu-submenu-title,
      .sidebar .ant-menu .ant-menu-item-selected .ant-menu-submenu-title,
      .sidebar .ant-menu .ant-menu-item-active .ant-menu-submenu-title,
      .sidebar .ant-menu .ant-menu-submenu-open .ant-menu-submenu-title {
        color: #0e0f36; }
        .sidebar .ant-menu .ant-menu-selected .ant-menu-submenu-title .anticon,
        .sidebar .ant-menu .ant-menu-active .ant-menu-submenu-title .anticon,
        .sidebar .ant-menu .ant-menu-item-selected .ant-menu-submenu-title .anticon,
        .sidebar .ant-menu .ant-menu-item-active .ant-menu-submenu-title .anticon,
        .sidebar .ant-menu .ant-menu-submenu-open .ant-menu-submenu-title .anticon {
          vertical-align: middle; }
        .sidebar .ant-menu .ant-menu-selected .ant-menu-submenu-title .text,
        .sidebar .ant-menu .ant-menu-active .ant-menu-submenu-title .text,
        .sidebar .ant-menu .ant-menu-item-selected .ant-menu-submenu-title .text,
        .sidebar .ant-menu .ant-menu-item-active .ant-menu-submenu-title .text,
        .sidebar .ant-menu .ant-menu-submenu-open .ant-menu-submenu-title .text {
          vertical-align: middle; }
        .sidebar .ant-menu .ant-menu-selected .ant-menu-submenu-title svg,
        .sidebar .ant-menu .ant-menu-active .ant-menu-submenu-title svg,
        .sidebar .ant-menu .ant-menu-item-selected .ant-menu-submenu-title svg,
        .sidebar .ant-menu .ant-menu-item-active .ant-menu-submenu-title svg,
        .sidebar .ant-menu .ant-menu-submenu-open .ant-menu-submenu-title svg {
          font-size: 1.25rem; }
        .sidebar .ant-menu .ant-menu-selected .ant-menu-submenu-title svg *,
        .sidebar .ant-menu .ant-menu-active .ant-menu-submenu-title svg *,
        .sidebar .ant-menu .ant-menu-item-selected .ant-menu-submenu-title svg *,
        .sidebar .ant-menu .ant-menu-item-active .ant-menu-submenu-title svg *,
        .sidebar .ant-menu .ant-menu-submenu-open .ant-menu-submenu-title svg * {
          fill: #0e0f36; }
    .sidebar .ant-menu .ant-menu-selected::after,
    .sidebar .ant-menu .ant-menu-active::after,
    .sidebar .ant-menu .ant-menu-item-selected::after,
    .sidebar .ant-menu .ant-menu-item-active::after {
      opacity: 0; }
    .sidebar .ant-menu .ant-menu-sub .ant-menu-item {
      margin-left: 0.8rem; }

@media (max-width: 1280px) {
  .sidebar {
    -webkit-flex: 0 0 190px !important;
            flex: 0 0 190px !important;
    max-width: 190px !important;
    min-width: 190px !important;
    width: 190px !important; } }

.app-footer {
  position: -webkit-sticky;
  position: sticky;
  top: 100%;
  padding: 1.6rem 0; }
  .app-footer ul {
    list-style: none;
    padding: 0; }
    .app-footer ul li {
      display: inline-block;
      vertical-align: middle;
      margin-right: 1.57rem; }
      .app-footer ul li a {
        color: #979797 !important;
        font-weight: 500; }
      .app-footer ul li img {
        max-height: 60px; }

.switch-account-modal .switch-account-text .title {
  color: #5D5C5C; }

.switch-account-modal .switch-account-text Button {
  display: block;
  width: 100%;
  min-height: 3rem;
  text-align: left;
  /* padding: initial; */
  font-size: 1rem;
  border: 2px solid #979797;
  margin-bottom: 1rem; }
  .switch-account-modal .switch-account-text Button span {
    width: 90%; }
    .switch-account-modal .switch-account-text Button span .ant-tag {
      margin-left: 15px; }
  .switch-account-modal .switch-account-text Button svg {
    width: 1rem;
    height: 1rem; }

.switch-account-modal .switch-account-text Button:hover {
  font-weight: 500;
  background: #E5DDFF;
  color: #5D5C5C; }
  .switch-account-modal .switch-account-text Button:hover svg {
    width: 1.3rem;
    height: 1.3rem; }

.no-table-data {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  padding: 2.11rem 0; }
  .no-table-data svg {
    width: 21.51rem;
    height: 16.44rem;
    max-width: 100%;
    margin-right: 1.36rem; }
  .no-table-data .text {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    width: 24.21rem; }
    .no-table-data .text .heading {
      font-size: 1.43rem;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.45);
      margin-bottom: 0.36rem; }
    .no-table-data .text .content {
      text-align: left;
      color: rgba(0, 0, 0, 0.45);
      margin-bottom: 0;
      font-size: 1.143rem;
      font-weight: 400; }

.snapshot .heading {
  font-size: 1.14rem;
  color: #5F5F5F; }

.snapshot .no-data {
  margin-top: 4rem;
  padding: 0;
  background: url(/static/media/backgroundPLaceholder.d708de91.svg);
  background-position-y: 4.3rem;
  background-position: bottom right;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #fff;
  min-height: 18.357rem;
  border-radius: 4px;
  margin-bottom: 2.88rem; }
  .snapshot .no-data .ant-card-body {
    padding: 3.12rem 4rem; }
    .snapshot .no-data .ant-card-body .heading {
      font-size: 1.786rem;
      font-weight: 500;
      color: #242425;
      margin-bottom: 0.236rem; }
    .snapshot .no-data .ant-card-body .text {
      color: rgba(0, 0, 0, 0.45);
      font-size: 1.143rem;
      width: 60%;
      margin-bottom: 1.26rem; }
    .snapshot .no-data .ant-card-body .ant-btn {
      background: #FF2F2F;
      color: #fff !important;
      text-decoration: none !important;
      font-size: 1rem;
      font-weight: 500;
      outline: none !important;
      border-color: #FF2F2F !important;
      height: 2.786rem;
      border-radius: 2px; }
    .snapshot .no-data .ant-card-body .ant-btn:hover,
    .snapshot .no-data .ant-card-body .ant-btn:active,
    .snapshot .no-data .ant-card-body .ant-btn:focus,
    .snapshot .no-data .ant-card-body .ant-btn:focus-within {
      background: rgba(255, 47, 47, 0.85) !important; }

.overall-stats .column .ant-card .antd-pro-charts-chart-card-meta {
  font-size: 0.78rem; }
  .overall-stats .column .ant-card .antd-pro-charts-chart-card-meta svg * {
    stroke: transparent;
    fill: rgba(0, 0, 0, 0.45); }

.overall-stats .column .ant-card .antd-pro-charts-chart-card-content .label {
  font-size: 1.78rem;
  color: #000;
  margin-bottom: 0.75rem; }
  .overall-stats .column .ant-card .antd-pro-charts-chart-card-content .label .anticon {
    margin-right: 0.75rem; }
    .overall-stats .column .ant-card .antd-pro-charts-chart-card-content .label .anticon svg * {
      stroke: transparent;
      fill: black; }

.overall-stats .column .ant-card .antd-pro-charts-chart-card-footer {
  font-size: 0.86rem; }
  .overall-stats .column .ant-card .antd-pro-charts-chart-card-footer .number-label {
    margin-right: 0.25rem; }
  .overall-stats .column .ant-card .antd-pro-charts-chart-card-footer .number {
    font-weight: 500;
    color: #000; }

@media (max-width: 991px) {
  .overall-stats .column {
    padding-bottom: 1rem; } }

.last-30-day-stats {
  padding-top: 3rem; }
  .last-30-day-stats .ant-card .ant-card-body {
    padding: 0rem;
    padding-top: 0.5rem; }
    .last-30-day-stats .ant-card .ant-card-body .ant-tabs-bar.ant-tabs-top-bar {
      padding-left: 3rem;
      padding-right: 3rem; }
    .last-30-day-stats .ant-card .ant-card-body .g2-tooltip-list-item::before {
      content: "\2022";
      color: red;
      font-weight: 600;
      font-size: 1.5rem;
      border-radius: 50%;
      margin-right: 0.25rem; }
    .last-30-day-stats .ant-card .ant-card-body .placeholder {
      text-align: center;
      padding: 2rem; }
      .last-30-day-stats .ant-card .ant-card-body .placeholder p {
        font-size: 2rem;
        font-weight: 700; }

.last-few-campaigns {
  padding-top: 2rem; }
  .last-few-campaigns .column {
    padding-top: 1rem;
    padding-bottom: 1rem; }

.campaign-card-container .name {
  font-size: 1.14rem;
  color: #080019;
  font-weight: 500; }

.campaign-card-container .ant-card .ant-card-body {
  padding: 0 1.5rem; }
  .campaign-card-container .ant-card .ant-card-body .ant-tabs-bar {
    margin-bottom: 0; }
    .campaign-card-container .ant-card .ant-card-body .ant-tabs-bar .ant-tabs-nav-container,
    .campaign-card-container .ant-card .ant-card-body .ant-tabs-bar .ant-tabs-extra-content {
      padding: 1.25rem 2rem;
      padding-bottom: 0; }
    .campaign-card-container .ant-card .ant-card-body .ant-tabs-bar .ant-tabs-extra-content .buttons-div {
      -webkit-flex: 1 1;
              flex: 1 1;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: flex-start;
              justify-content: flex-start;
      -webkit-flex-flow: row wrap;
              flex-flow: row wrap; }
      .campaign-card-container .ant-card .ant-card-body .ant-tabs-bar .ant-tabs-extra-content .buttons-div .ant-btn.ant-btn-danger {
        margin-right: 0.5rem;
        background: #23202C;
        border-color: #23202C !important;
        color: #FFFFFF; }
      .campaign-card-container .ant-card .ant-card-body .ant-tabs-bar .ant-tabs-extra-content .buttons-div .ant-btn.ant-btn-danger:active,
      .campaign-card-container .ant-card .ant-card-body .ant-tabs-bar .ant-tabs-extra-content .buttons-div .ant-btn.ant-btn-danger:hover {
        background: rgba(35, 32, 44, 0.9);
        color: #FFFFFF; }
      .campaign-card-container .ant-card .ant-card-body .ant-tabs-bar .ant-tabs-extra-content .buttons-div .ant-btn.ant-btn-primary {
        background: #36C626;
        border-color: #36C626 !important;
        color: #FFFFFF; }
      .campaign-card-container .ant-card .ant-card-body .ant-tabs-bar .ant-tabs-extra-content .buttons-div .ant-btn.ant-btn-primary:active,
      .campaign-card-container .ant-card .ant-card-body .ant-tabs-bar .ant-tabs-extra-content .buttons-div .ant-btn.ant-btn-primary:hover {
        background: rgba(54, 198, 38, 0.9);
        color: #FFFFFF; }
  .campaign-card-container .ant-card .ant-card-body .card-length-changer {
    color: #8348FF;
    font-size: 1rem;
    cursor: pointer;
    text-align: center;
    padding-top: 1rem;
    font-weight: 500; }

.campaign-card-container .ant-card .tag {
  border-radius: 20px;
  border: none;
  padding: 0rem 1rem;
  margin-right: 2rem;
  font-size: 0.86rem; }

.campaign-card-container .ant-card .ant-card-head {
  padding: 0.5rem 0.5rem; }
  .campaign-card-container .ant-card .ant-card-head .ant-card-head-title {
    padding: 0; }
    .campaign-card-container .ant-card .ant-card-head .ant-card-head-title .card-title-div {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-flow: row wrap;
              flex-flow: row wrap;
      -webkit-justify-content: space-between;
              justify-content: space-between;
      background: #FAFAFA;
      padding: 1rem 2rem;
      -webkit-align-items: center;
              align-items: center; }
      .campaign-card-container .ant-card .ant-card-head .ant-card-head-title .card-title-div .name-div {
        -webkit-flex: 0.2 1;
                flex: 0.2 1; }
        .campaign-card-container .ant-card .ant-card-head .ant-card-head-title .card-title-div .name-div .name {
          margin-bottom: 0;
          color: #23202C;
          font-size: 1.28rem;
          text-transform: capitalize; }
      .campaign-card-container .ant-card .ant-card-head .ant-card-head-title .card-title-div .created-at-div {
        -webkit-flex: 0.25 1;
                flex: 0.25 1; }
        .campaign-card-container .ant-card .ant-card-head .ant-card-head-title .card-title-div .created-at-div .created-at {
          color: rgba(0, 0, 0, 0.65);
          font-size: 1rem;
          margin-bottom: 0; }
      .campaign-card-container .ant-card .ant-card-head .ant-card-head-title .card-title-div .stats-div {
        -webkit-flex: 0.35 1;
                flex: 0.35 1;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: space-evenly;
                justify-content: space-evenly;
        text-align: center; }
        .campaign-card-container .ant-card .ant-card-head .ant-card-head-title .card-title-div .stats-div .stats {
          font-size: 1rem;
          color: #080019; }
          .campaign-card-container .ant-card .ant-card-head .ant-card-head-title .card-title-div .stats-div .stats .anticon svg {
            height: 1.43rem;
            width: 1.43rem; }
          .campaign-card-container .ant-card .ant-card-head .ant-card-head-title .card-title-div .stats-div .stats .normal-stat {
            display: block; }
          .campaign-card-container .ant-card .ant-card-head .ant-card-head-title .card-title-div .stats-div .stats .hover-stat {
            display: none; }
        .campaign-card-container .ant-card .ant-card-head .ant-card-head-title .card-title-div .stats-div .stats:hover .normal-stat {
          display: none; }
        .campaign-card-container .ant-card .ant-card-head .ant-card-head-title .card-title-div .stats-div .stats:hover .hover-stat {
          display: block; }
      .campaign-card-container .ant-card .ant-card-head .ant-card-head-title .card-title-div .actions-div {
        -webkit-flex: 0.1 1;
                flex: 0.1 1;
        font-size: 1rem; }
        .campaign-card-container .ant-card .ant-card-head .ant-card-head-title .card-title-div .actions-div .ant-dropdown-link {
          color: #595959; }
        .campaign-card-container .ant-card .ant-card-head .ant-card-head-title .card-title-div .actions-div .ant-dropdown-link:hover,
        .campaign-card-container .ant-card .ant-card-head .ant-card-head-title .card-title-div .actions-div .ant-dropdown-link:active {
          color: #000; }

.campaign-card-container .ant-alert.ant-alert-warning {
  position: absolute;
  top: 10px;
  left: 33%;
  width: 35%;
  z-index: 9;
  border: 1px solid #FDE58F;
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.16); }

.campaign-card-actions-menu {
  margin-right: 1rem; }
  .campaign-card-actions-menu .ant-dropdown-menu-item {
    padding: 0; }
    .campaign-card-actions-menu .ant-dropdown-menu-item a {
      font-size: 1rem;
      padding: 1rem 5rem 0.75rem 2rem; }
      .campaign-card-actions-menu .ant-dropdown-menu-item a .ant-switch {
        vertical-align: baseline;
        margin-left: 0.5rem; }
      .campaign-card-actions-menu .ant-dropdown-menu-item a .ant-switch-checked {
        background: #36C626; }

.campaign-overview .ant-table-thead > tr:first-child > th:first-child {
  padding-left: 2rem; }

.campaign-overview .ant-table-tbody > tr > td {
  color: #8C8C8C;
  font-size: 1rem; }
  .campaign-overview .ant-table-tbody > tr > td .ant-switch {
    vertical-align: baseline;
    margin-left: 0.2rem; }
  .campaign-overview .ant-table-tbody > tr > td .ant-switch-checked {
    background: #36C626; }

.campaign-overview .ant-table-tbody > tr > td:first-child {
  padding-left: 2rem; }

.campaign-details {
  padding: 2.5rem 3rem; }
  .campaign-details .title {
    color: #080019;
    font-size: 1.28rem;
    font-weight: 500; }
  .campaign-details .text {
    background: #F1F0F5;
    color: rgba(0, 0, 0, 0.45);
    font-size: 0.95rem;
    padding: 0.5rem 1rem;
    overflow: hidden;
    text-overflow: ellipsis; }
    .campaign-details .text .type {
      text-transform: capitalize; }
  .campaign-details .text.name,
  .campaign-details .text.record-source {
    text-transform: capitalize; }


.records-table .ant-table {
  font-size: 1rem; }
  .records-table .ant-table .ant-table-tbody > tr > td {
    color: rgba(0, 0, 0, 0.45); }
    .records-table .ant-table .ant-table-tbody > tr > td .details-div {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-flow: row;
              flex-flow: row;
      -webkit-align-content: center;
              align-content: center; }
      .records-table .ant-table .ant-table-tbody > tr > td .details-div img {
        margin-right: 1rem; }
      .records-table .ant-table .ant-table-tbody > tr > td .details-div .text {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-flow: column wrap;
                flex-flow: column wrap;
        -webkit-justify-content: center;
                justify-content: center;
        margin: 0; }
        .records-table .ant-table .ant-table-tbody > tr > td .details-div .text .name {
          color: rgba(0, 0, 0, 0.65);
          font-weight: 500; }
        .records-table .ant-table .ant-table-tbody > tr > td .details-div .text .email {
          max-width: 10rem;
          font-size: 0.8rem;
          overflow: hidden;
          text-overflow: ellipsis; }
    .records-table .ant-table .ant-table-tbody > tr > td .delete-button {
      height: 45.75px;
      font-size: 1rem; }
    .records-table .ant-table .ant-table-tbody > tr > td .delete-button:hover {
      background: #F84B3D !important; }
      .records-table .ant-table .ant-table-tbody > tr > td .delete-button:hover svg * {
        fill: white; }
    .records-table .ant-table .ant-table-tbody > tr > td .record-status {
      margin-bottom: 0; }
      .records-table .ant-table .ant-table-tbody > tr > td .record-status .ant-switch {
        margin-left: 0.5rem;
        vertical-align: baseline; }
      .records-table .ant-table .ant-table-tbody > tr > td .record-status .ant-switch-checked {
        background: #36C626; }

.main-heading-bar {
  padding: 3rem 0 2rem 0;
  color: #000; }
  .main-heading-bar .title {
    font-size: 1.78rem;
    font-weight: 500;
    margin-bottom: 0;
    color: #2B2B2B; }
  .main-heading-bar .subtitle {
    font-size: 1.1rem;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
    margin-bottom: 0; }
  .main-heading-bar .page-cta {
    margin-top: 6px; }
  .main-heading-bar .actions {
    text-align: right; }
    .main-heading-bar .actions .ant-btn {
      background: #EB5703;
      color: #fff;
      outline: none !important;
      border-color: #EB5703 !important;
      border-radius: 2px;
      font-size: 1rem; }

.steps-card {
  margin-top: 4.286rem;
  padding: 0; }
  .steps-card .ant-card-body {
    padding: 1.857rem 2.43rem; }
    .steps-card .ant-card-body .left {
      border: 1px solid #979797;
      border-radius: 4px;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-align-items: center;
              align-items: center;
      padding-top: 1.836rem;
      padding-bottom: 2.52rem; }
      .steps-card .ant-card-body .left .number {
        font-size: 2.857rem;
        font-weight: 500;
        color: #242425;
        margin-bottom: 0.02rem; }
      .steps-card .ant-card-body .left .text {
        font-size: 1.143rem;
        color: #242425;
        font-weight: 400;
        margin-bottom: 1.764rem; }
      .steps-card .ant-card-body .left .ant-btn {
        font-size: 1rem;
        font-weight: 600;
        background: #36C626;
        border: none !important;
        outline: none !important;
        color: #fff;
        padding: 0.714rem 1.143rem;
        height: 2.857rem;
        border-radius: 4px; }
        .steps-card .ant-card-body .left .ant-btn svg {
          width: 1.286rem;
          height: 1.286rem;
          vertical-align: text-bottom;
          margin-left: 0.43rem; }
          .steps-card .ant-card-body .left .ant-btn svg * {
            fill: #fff; }
      .steps-card .ant-card-body .left .ant-btn:hover {
        background: rgba(54, 198, 38, 0.85); }
    .steps-card .ant-card-body .right .heading {
      color: #242425;
      font-size: 1.786rem;
      font-weight: 500;
      margin-bottom: 0.236rem; }
    .steps-card .ant-card-body .right .disclaimer {
      font-size: 1.143rem;
      color: rgba(0, 0, 0, 0.45);
      font-weight: 400;
      margin-bottom: 0.76rem; }
    .steps-card .ant-card-body .right .step-name {
      color: #242425;
      background: rgba(54, 198, 38, 0.33);
      padding: 2px 7px;
      display: inline-block;
      border-radius: 3px;
      font-size: 1.143rem;
      font-weight: 500;
      margin-bottom: 0.59rem; }
    .steps-card .ant-card-body .right .step-detail {
      color: rgba(0, 0, 0, 0.65);
      font-size: 1.0rem;
      font-weight: 400;
      margin-bottom: 0.36rem; }
    .steps-card .ant-card-body .right .bottom-text {
      font-size: 1.143rem;
      font-style: italic;
      color: rgba(0, 0, 0, 0.65);
      font-weight: 400; }
    .steps-card .ant-card-body .image {
      padding: 0 !important; }
      .steps-card .ant-card-body .image svg {
        width: 20.467rem;
        max-width: 100%;
        height: 14.418rem; }

.steps-modal .ant-modal-content {
  border-radius: 3px; }

.steps-modal .ant-modal-body {
  padding: 4.22rem 5.64rem;
  position: relative; }
  .steps-modal .ant-modal-body .close-btn {
    position: absolute;
    top: 2rem;
    right: 2rem;
    width: 2rem;
    height: 2rem;
    cursor: pointer; }
  .steps-modal .ant-modal-body .top-div {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    margin-bottom: 1.56rem; }
    .steps-modal .ant-modal-body .top-div .left {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-align-items: flex-start;
              align-items: flex-start;
      -webkit-justify-content: flex-start;
              justify-content: flex-start; }
      .steps-modal .ant-modal-body .top-div .left .title {
        font-size: 1.786rem;
        font-weight: 600;
        text-transform: capitalize;
        margin-bottom: 0.21rem; }
        .steps-modal .ant-modal-body .top-div .left .title img {
          max-width: 100%;
          width: 1.786rem;
          height: 1.786rem;
          margin-right: 0.25rem; }
      .steps-modal .ant-modal-body .top-div .left .sub-title {
        font-size: 1.286rem;
        font-weight: 400;
        color: #8C8C8C;
        -webkit-margin-after: 0.71rem;
                margin-block-end: 0.71rem; }
      .steps-modal .ant-modal-body .top-div .left .steps-ratio {
        border-radius: 3px;
        background: rgba(54, 198, 38, 0.33);
        height: 3rem;
        width: 16.86rem;
        max-width: 100%;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
        -webkit-justify-content: space-evenly;
                justify-content: space-evenly;
        font-size: 1.286rem;
        color: #020429;
        font-weight: 600; }
    .steps-modal .ant-modal-body .top-div .right svg {
      width: 14.7rem;
      height: 12.86rem;
      max-width: 100%;
      max-height: 100%; }
  .steps-modal .ant-modal-body .list-div .step {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: flex-start;
            align-items: flex-start; }
    .steps-modal .ant-modal-body .list-div .step svg {
      width: 1.71rem;
      height: 1.71rem;
      margin-right: 1.43rem; }
    .steps-modal .ant-modal-body .list-div .step .number {
      font-size: 1.143rem;
      color: #9C9C9C;
      border: 2px solid #9C9C9C;
      border-radius: 50%;
      height: 1.71rem;
      width: 1.71rem;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: center;
              justify-content: center;
      margin-right: 1.43rem; }
    .steps-modal .ant-modal-body .list-div .step .text .heading {
      font-size: 1.143rem;
      font-weight: 500;
      margin-bottom: 0.071rem;
      color: #36C626; }
      .steps-modal .ant-modal-body .list-div .step .text .heading svg {
        width: 1.286rem;
        height: 1.286rem;
        vertical-align: text-bottom;
        margin-right: 0.286rem;
        display: none; }
    .steps-modal .ant-modal-body .list-div .step .text .content {
      font-size: 1rem;
      font-weight: 400;
      color: #8C8C8C; }
  .steps-modal .ant-modal-body .list-div .step:last-of-type .text .content {
    margin-bottom: 0; }
  .steps-modal .ant-modal-body .list-div .step.incomplete .text .heading {
    color: #8C8C8C; }
  .steps-modal .ant-modal-body .list-div .step.incomplete .text .heading:hover {
    color: #36C525; }
    .steps-modal .ant-modal-body .list-div .step.incomplete .text .heading:hover svg {
      display: inline-block; }
  .steps-modal .ant-modal-body .list-div .step.first-incomplete .text .heading, .steps-modal .ant-modal-body .list-div .step.first-incomplete .number {
    color: #fff;
    background: #36C525;
    border-color: #36C525; }
  .steps-modal .ant-modal-body .list-div .step.first-incomplete .text .heading {
    border-radius: 3px;
    padding: 0.2rem 0.5rem; }
    .steps-modal .ant-modal-body .list-div .step.first-incomplete .text .heading svg * {
      fill: #fff; }
  .steps-modal .ant-modal-body .list-div .step.first-incomplete .text .heading:hover svg {
    display: inline-block; }

.switch-account-modal .switch-account-text .title {
  color: #5D5C5C; }

.switch-account-modal .switch-account-text Button {
  display: block;
  width: 100%;
  min-height: 3rem;
  text-align: left;
  /* padding: initial; */
  font-size: 1rem;
  border: 2px solid #979797;
  margin-bottom: 1rem; }
  .switch-account-modal .switch-account-text Button span {
    width: 90%; }
    .switch-account-modal .switch-account-text Button span .ant-tag {
      margin-left: 15px; }
  .switch-account-modal .switch-account-text Button svg {
    width: 1rem;
    height: 1rem; }

.switch-account-modal .switch-account-text Button:hover {
  font-weight: 500;
  background: #E5DDFF;
  color: #5D5C5C; }
  .switch-account-modal .switch-account-text Button:hover svg {
    width: 1.3rem;
    height: 1.3rem; }

.switch-account-modal .switch-account-text .title {
  color: #5D5C5C; }

.switch-account-modal .switch-account-text Button {
  display: block;
  width: 100%;
  min-height: 3rem;
  text-align: left;
  /* padding: initial; */
  font-size: 1rem;
  border: 2px solid #979797;
  margin-bottom: 1rem; }
  .switch-account-modal .switch-account-text Button span {
    width: 90%; }
    .switch-account-modal .switch-account-text Button span .ant-tag {
      margin-left: 15px; }
  .switch-account-modal .switch-account-text Button svg {
    width: 1rem;
    height: 1rem; }

.switch-account-modal .switch-account-text Button:hover {
  font-weight: 500;
  background: #E5DDFF;
  color: #5D5C5C; }
  .switch-account-modal .switch-account-text Button:hover svg {
    width: 1.3rem;
    height: 1.3rem; }

.records .ant-card {
  border-radius: 2px; }
  .records .ant-card .ant-card-body {
    padding: 1.5rem 1rem 0.5rem 1rem; }
    .records .ant-card .ant-card-body .multiple-actions-div {
      margin-bottom: 1rem;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-between;
              justify-content: space-between;
      -webkit-flex-flow: row wrap;
              flex-flow: row wrap; }
      .records .ant-card .ant-card-body .multiple-actions-div div {
        display: inline-block; }
      .records .ant-card .ant-card-body .multiple-actions-div .buttons-div {
        -webkit-flex: 1 1;
                flex: 1 1;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: flex-start;
                justify-content: flex-start;
        -webkit-flex-flow: row wrap;
                flex-flow: row wrap; }
        .records .ant-card .ant-card-body .multiple-actions-div .buttons-div .ant-btn.ant-btn-danger {
          margin-right: 0.5rem;
          background: #23202C;
          border-color: #23202C !important;
          color: #FFFFFF; }
        .records .ant-card .ant-card-body .multiple-actions-div .buttons-div .ant-btn.ant-btn-danger:active,
        .records .ant-card .ant-card-body .multiple-actions-div .buttons-div .ant-btn.ant-btn-danger:hover {
          background: rgba(35, 32, 44, 0.9);
          color: #FFFFFF; }
        .records .ant-card .ant-card-body .multiple-actions-div .buttons-div .ant-btn.ant-btn-primary {
          background: #36C626;
          border-color: #36C626 !important;
          color: #FFFFFF; }
        .records .ant-card .ant-card-body .multiple-actions-div .buttons-div .ant-btn.ant-btn-primary:active,
        .records .ant-card .ant-card-body .multiple-actions-div .buttons-div .ant-btn.ant-btn-primary:hover {
          background: rgba(54, 198, 38, 0.9);
          color: #FFFFFF; }
      .records .ant-card .ant-card-body .multiple-actions-div .buttons-div.hidden {
        visibility: hidden; }
      .records .ant-card .ant-card-body .multiple-actions-div .search-div {
        -webkit-flex: 0.5 1;
                flex: 0.5 1;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: space-between;
                justify-content: space-between;
        -webkit-flex-flow: row wrap;
                flex-flow: row wrap; }
        .records .ant-card .ant-card-body .multiple-actions-div .search-div p.search-label {
          -webkit-flex: 0.3 1;
                  flex: 0.3 1;
          margin-bottom: 0;
          -webkit-align-self: center;
                  align-self: center; }
        .records .ant-card .ant-card-body .multiple-actions-div .search-div .ant-input-search {
          -webkit-flex: 1 1;
                  flex: 1 1;
          width: auto; }

.user-reviews {
  padding-top: 0; }
  .user-reviews .heading {
    color: rgba(0, 0, 0, 0.65);
    margin-bottom: 0;
    font-size: 1.28rem;
    font-weight: 300; }
  .user-reviews .ant-card {
    border-radius: 2px; }
    .user-reviews .ant-card .ant-card-body {
      padding: 0;
      padding-bottom: 0.5rem; }
      .user-reviews .ant-card .ant-card-body .ant-table .ant-table-tbody > tr > td .review-preview {
        max-width: 300px; }
      .user-reviews .ant-card .ant-card-body .ant-table .ant-table-tbody > tr > td .actions-div .ant-btn {
        height: 2.5rem;
        margin-right: 0.714rem;
        padding: 0 0.714rem; }
        .user-reviews .ant-card .ant-card-body .ant-table .ant-table-tbody > tr > td .actions-div .ant-btn span {
          display: none; }
        .user-reviews .ant-card .ant-card-body .ant-table .ant-table-tbody > tr > td .actions-div .ant-btn svg {
          width: 1.14rem;
          height: 1.14rem; }
        .user-reviews .ant-card .ant-card-body .ant-table .ant-table-tbody > tr > td .actions-div .ant-btn .icon {
          width: 1.14rem;
          height: 1.14rem;
          overflow: hidden;
          -webkit-transition: width 0.25s;
          transition: width 0.25s; }
        .user-reviews .ant-card .ant-card-body .ant-table .ant-table-tbody > tr > td .actions-div .ant-btn .text {
          display: inline-block;
          height: 1.14rem;
          margin: 0;
          width: 0;
          overflow: hidden;
          -webkit-transition: width 0.25s;
          transition: width 0.25s; }
      .user-reviews .ant-card .ant-card-body .ant-table .ant-table-tbody > tr > td .actions-div .edit-btn {
        border: 1px solid #23202C; }
      .user-reviews .ant-card .ant-card-body .ant-table .ant-table-tbody > tr > td .actions-div .edit-btn:hover {
        background: #23202C;
        color: white; }
        .user-reviews .ant-card .ant-card-body .ant-table .ant-table-tbody > tr > td .actions-div .edit-btn:hover svg * {
          fill: white; }
      .user-reviews .ant-card .ant-card-body .ant-table .ant-table-tbody > tr > td .actions-div .delete-btn {
        border: 1px solid #F84B3D;
        background: white; }
      .user-reviews .ant-card .ant-card-body .ant-table .ant-table-tbody > tr > td .actions-div .delete-btn:hover {
        background: #F84B3D;
        color: white; }
        .user-reviews .ant-card .ant-card-body .ant-table .ant-table-tbody > tr > td .actions-div .delete-btn:hover svg * {
          fill: white; }

.review-preview {
  margin: 0; }
  .review-preview .review-previewimage {
    width: 48px;
    height: 48px;
    display: inline-block;
    background: #FAFAFA;
    border-radius: 4px;
    vertical-align: middle; }
  .review-preview .review-content {
    display: inline-block;
    vertical-align: middle;
    padding-left: 10px;
    width: calc(100% - 58px); }
    .review-preview .review-content .reviewer_name {
      margin: 0;
      width: 100%; }
    .review-preview .review-content .review_text {
      width: 100%;
      color: rgba(0, 0, 0, 0.45);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      position: relative;
      margin-bottom: 0; }
    .review-preview .review-content .review_text:before, .review-preview .review-content .review_text:after {
      content: '"'; }

.ant-select-selection__choice__content .review_text {
  display: none; }

.ant-select-selection__choice__content .review_stars {
  display: none; }

.ant-select-selection__choice__content .review-previewimage {
  width: 15px;
  height: 15px; }

.library-modal {
  width: 38rem !important; }
  .library-modal .ant-modal-content {
    padding: 3.14rem 4.14rem; }
    .library-modal .ant-modal-content .ant-modal-close {
      right: 3.14rem;
      top: 2.34rem;
      color: black; }
      .library-modal .ant-modal-content .ant-modal-close .anticon {
        font-size: 20px; }
    .library-modal .ant-modal-content .ant-modal-header {
      padding: 0;
      border: 0;
      margin-bottom: 2.14rem; }
      .library-modal .ant-modal-content .ant-modal-header .ant-modal-title {
        font-size: 1.78rem;
        font-weight: 500; }
    .library-modal .ant-modal-content .ant-modal-body {
      padding: 0; }
    .library-modal .ant-modal-content .choose-image-from-library {
      max-height: 30rem;
      overflow: scroll; }

.add-review-modal {
  width: 38rem !important; }
  .add-review-modal .ant-modal-content {
    padding: 3.14rem 4.14rem; }
    .add-review-modal .ant-modal-content .ant-modal-close {
      right: 3.14rem;
      top: 2.34rem;
      color: black; }
      .add-review-modal .ant-modal-content .ant-modal-close .anticon {
        font-size: 20px; }
    .add-review-modal .ant-modal-content .ant-modal-header {
      padding: 0;
      border: 0; }
      .add-review-modal .ant-modal-content .ant-modal-header .ant-modal-title {
        font-size: 1.78rem;
        font-weight: 500; }
    .add-review-modal .ant-modal-content .ant-modal-body {
      padding: 0; }
      .add-review-modal .ant-modal-content .ant-modal-body .add-review-form {
        margin-top: 1.1rem; }
        .add-review-modal .ant-modal-content .ant-modal-body .add-review-form .submit-btn {
          border-color: #36C626;
          background: #36C626;
          color: white;
          display: block;
          width: 20rem;
          max-width: 100%;
          margin: 0 auto; }
        .add-review-modal .ant-modal-content .ant-modal-body .add-review-form .ant-form-item-control {
          margin-bottom: 1.14rem; }
          .add-review-modal .ant-modal-content .ant-modal-body .add-review-form .ant-form-item-control label {
            line-height: 1.57rem;
            font-size: 1.14rem;
            font-weight: 400; }
          .add-review-modal .ant-modal-content .ant-modal-body .add-review-form .ant-form-item-control .ant-input, .add-review-modal .ant-modal-content .ant-modal-body .add-review-form .ant-form-item-control .ant-select {
            margin-top: 0.71rem !important; }
          .add-review-modal .ant-modal-content .ant-modal-body .add-review-form .ant-form-item-control .upload-actions {
            margin-top: 0.71rem;
            display: -webkit-flex;
            display: flex; }
            .add-review-modal .ant-modal-content .ant-modal-body .add-review-form .ant-form-item-control .upload-actions .upload-action {
              width: 7.14rem;
              height: 7.14rem;
              border: 1px solid rgba(0, 0, 0, 0.15);
              border-radius: 3px;
              color: rgba(0, 0, 0, 0.25);
              font-size: 1rem;
              text-align: center;
              display: -webkit-flex;
              display: flex;
              -webkit-align-items: center;
                      align-items: center;
              -webkit-justify-content: center;
                      justify-content: center;
              margin-right: 0.5rem;
              cursor: pointer; }
              .add-review-modal .ant-modal-content .ant-modal-body .add-review-form .ant-form-item-control .upload-actions .upload-action .anticon {
                font-size: 2.25rem; }

.review-pools .heading {
  color: rgba(0, 0, 0, 0.65);
  margin-bottom: 0;
  font-size: 1.28rem;
  font-weight: 300; }

.review-pools .ant-card {
  border-radius: 2px; }
  .review-pools .ant-card .ant-card-body {
    padding: 0;
    padding-bottom: 0.5rem; }
    .review-pools .ant-card .ant-card-body .actions-div .ant-btn {
      height: 2.5rem;
      margin-right: 0.714rem;
      padding: 0 0.714rem;
      display: inline-block;
      vertical-align: middle; }
      .review-pools .ant-card .ant-card-body .actions-div .ant-btn svg {
        width: 1.14rem;
        height: 1.14rem; }
      .review-pools .ant-card .ant-card-body .actions-div .ant-btn .icon {
        width: 1.14rem;
        height: 1.14rem;
        overflow: hidden;
        -webkit-transition: width 0.25s;
        transition: width 0.25s; }
    .review-pools .ant-card .ant-card-body .actions-div .edit-btn {
      border: 1px solid #23202C;
      text-decoration: none !important;
      line-height: 2.55rem; }
      .review-pools .ant-card .ant-card-body .actions-div .edit-btn i {
        margin-top: 10px; }
    .review-pools .ant-card .ant-card-body .actions-div .edit-btn:hover {
      background: #23202C; }
      .review-pools .ant-card .ant-card-body .actions-div .edit-btn:hover svg * {
        stroke: #fff;
        fill: #fff; }
    .review-pools .ant-card .ant-card-body .actions-div .delete-btn {
      border: 1px solid #F84B3D;
      background: white; }
      .review-pools .ant-card .ant-card-body .actions-div .delete-btn i {
        margin-top: 4px; }
    .review-pools .ant-card .ant-card-body .actions-div .delete-btn:hover {
      background: #F84B3D; }
      .review-pools .ant-card .ant-card-body .actions-div .delete-btn:hover svg * {
        fill: #fff; }

.review-sources .heading {
  color: rgba(0, 0, 0, 0.65);
  margin-bottom: 0;
  font-size: 1.28rem;
  font-weight: 300; }

.review-sources .ant-card {
  border-radius: 2px; }
  .review-sources .ant-card .ant-card-body {
    padding: 0;
    padding-bottom: 0.5rem; }
    .review-sources .ant-card .ant-card-body .actions-div .ant-btn {
      height: 2.5rem;
      margin-right: 0.714rem;
      padding: 0 0.714rem;
      display: inline-block;
      vertical-align: middle; }
      .review-sources .ant-card .ant-card-body .actions-div .ant-btn svg {
        width: 1.14rem;
        height: 1.14rem; }
      .review-sources .ant-card .ant-card-body .actions-div .ant-btn .icon {
        width: 1.14rem;
        height: 1.14rem;
        overflow: hidden;
        -webkit-transition: width 0.25s;
        transition: width 0.25s; }
    .review-sources .ant-card .ant-card-body .actions-div .edit-btn {
      border: 1px solid #23202C;
      text-decoration: none !important;
      line-height: 2.55rem; }
    .review-sources .ant-card .ant-card-body .actions-div .edit-btn:hover {
      background: #23202C;
      color: white; }
      .review-sources .ant-card .ant-card-body .actions-div .edit-btn:hover svg * {
        fill: #fff; }
    .review-sources .ant-card .ant-card-body .actions-div .delete-btn {
      border: 1px solid #F84B3D;
      background: white; }
    .review-sources .ant-card .ant-card-body .actions-div .delete-btn:hover {
      background: #F84B3D;
      color: white; }
      .review-sources .ant-card .ant-card-body .actions-div .delete-btn:hover svg * {
        fill: #fff; }

.add-source-modal {
  width: 70rem !important; }
  .add-source-modal .ant-modal-body {
    padding: 24px 0; }
  .add-source-modal .ant-modal-content {
    padding: 3.14rem 4.14rem; }
    .add-source-modal .ant-modal-content .ant-modal-close {
      right: 3.14rem;
      top: 2.34rem;
      color: black; }
      .add-source-modal .ant-modal-content .ant-modal-close .anticon {
        font-size: 20px; }
    .add-source-modal .ant-modal-content .ant-modal-header {
      padding: 0;
      border: 0; }
      .add-source-modal .ant-modal-content .ant-modal-header .ant-modal-title {
        font-size: 1.78rem;
        font-weight: 500; }
    .add-source-modal .ant-modal-content .source-box {
      border: 1px solid rgba(0, 0, 0, 0.15);
      border-radius: 3px;
      -webkit-transition-duration: 0.3s;
              transition-duration: 0.3s;
      height: 10rem;
      cursor: pointer; }
      .add-source-modal .ant-modal-content .source-box img {
        max-width: 70%;
        display: block;
        margin: auto; }
      .add-source-modal .ant-modal-content .source-box .text {
        visibility: hidden;
        color: #36C626;
        font-weight: 500;
        text-align: center; }
    .add-source-modal .ant-modal-content .source-box:hover {
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16); }
      .add-source-modal .ant-modal-content .source-box:hover .text {
        visibility: visible; }

.google-review-modal {
  width: 38rem !important; }
  .google-review-modal .ant-modal-content {
    padding: 3.14rem 4.14rem; }
    .google-review-modal .ant-modal-content .ant-modal-close {
      right: 3.14rem;
      top: 2.34rem;
      color: black; }
      .google-review-modal .ant-modal-content .ant-modal-close .anticon {
        font-size: 20px; }
    .google-review-modal .ant-modal-content .ant-modal-header {
      padding: 0;
      border: 0; }
      .google-review-modal .ant-modal-content .ant-modal-header .ant-modal-title {
        font-size: 1.78rem;
        font-weight: 500; }
    .google-review-modal .ant-modal-content .ant-modal-body {
      padding: 0; }
      .google-review-modal .ant-modal-content .ant-modal-body .add-source-form {
        margin-top: 1.1rem; }
        .google-review-modal .ant-modal-content .ant-modal-body .add-source-form .submit-btn {
          border-color: #36C626;
          background: #36C626;
          color: white;
          display: block;
          width: 20rem;
          max-width: 100%;
          margin: 0 auto; }
        .google-review-modal .ant-modal-content .ant-modal-body .add-source-form .ant-form-item-control {
          margin-bottom: 1.14rem; }
          .google-review-modal .ant-modal-content .ant-modal-body .add-source-form .ant-form-item-control label {
            line-height: 1.57rem;
            font-size: 1.14rem;
            font-weight: 400; }
          .google-review-modal .ant-modal-content .ant-modal-body .add-source-form .ant-form-item-control .ant-input, .google-review-modal .ant-modal-content .ant-modal-body .add-source-form .ant-form-item-control .ant-select {
            margin-top: 0.71rem !important; }

.library .heading {
  color: rgba(0, 0, 0, 0.65);
  margin-bottom: 1.5rem;
  font-size: 1.28rem;
  font-weight: 300; }

.library .ant-card-cover {
  height: 10rem;
  overflow: hidden; }

.library .selected-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(24, 144, 255, 0.3); }

.library .media-meta p {
  margin: 0;
  color: rgba(0, 0, 0, 0.45);
  font-size: 0.75rem; }

.library .media-meta .ant-checkbox-inner {
  width: 1.5rem;
  height: 1.5rem; }

.library .media-meta .ant-checkbox-inner:after {
  width: 0.48rem;
  height: 1.07rem;
  left: 4.571429px;
  top: 9.142857px; }

.library .media-meta .ant-checkbox-wrapper {
  position: absolute;
  bottom: -1px;
  right: 0; }

.library .tenor-logo {
  width: 200px;
  margin: auto; }
  .library .tenor-logo svg {
    width: 100%; }
  .library .tenor-logo svg * {
    stroke: black;
    fill: black; }

.library .heading {
  color: rgba(0, 0, 0, 0.65);
  margin-bottom: 1.5rem;
  font-size: 1.28rem;
  font-weight: 300; }

.library .ant-card-cover {
  height: 10rem;
  overflow: hidden; }

.library .selected-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(24, 144, 255, 0.3); }

.library .media-meta p {
  margin: 0;
  color: rgba(0, 0, 0, 0.45);
  font-size: 0.75rem; }

.library .media-meta .ant-checkbox-inner {
  width: 1.5rem;
  height: 1.5rem; }

.library .media-meta .ant-checkbox-inner:after {
  width: 0.48rem;
  height: 1.07rem;
  left: 4.571429px;
  top: 9.142857px; }

.library .media-meta .ant-checkbox-wrapper {
  position: absolute;
  bottom: -1px;
  right: 0; }

.campaigns-container .main-heading-bar span.sort {
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.65);
  font-weight: 400; }

.campaigns-container .campaigns .column {
  margin-bottom: 1rem; }

.manual-records .edit-btn {
  margin-right: 8px; }
  .manual-records .edit-btn .anticon {
    margin-left: 0; }

.manual-records .edit-btn:hover {
  background: black;
  border-color: black; }
  .manual-records .edit-btn:hover svg * {
    fill: white; }

.manual-records .delete-btn:hover svg * {
  fill: white; }

.add-manual-record-modal {
  width: 38rem !important; }
  .add-manual-record-modal .ant-modal-content {
    padding: 3.14rem 4.14rem; }
    .add-manual-record-modal .ant-modal-content .ant-modal-close {
      right: 3.14rem;
      top: 2.34rem;
      color: black; }
      .add-manual-record-modal .ant-modal-content .ant-modal-close .anticon {
        font-size: 20px; }
    .add-manual-record-modal .ant-modal-content .ant-modal-header {
      padding: 0;
      border: 0; }
      .add-manual-record-modal .ant-modal-content .ant-modal-header .ant-modal-title {
        font-size: 1.78rem;
        font-weight: 500; }
    .add-manual-record-modal .ant-modal-content .ant-modal-body {
      padding: 0; }
      .add-manual-record-modal .ant-modal-content .ant-modal-body .add-record-form {
        margin-top: 1.1rem; }
        .add-manual-record-modal .ant-modal-content .ant-modal-body .add-record-form .submit-btn {
          border-color: #36C626;
          background: #36C626;
          color: white;
          display: block;
          width: 20rem;
          max-width: 100%;
          margin: 0 auto; }
        .add-manual-record-modal .ant-modal-content .ant-modal-body .add-record-form .ant-form-item-control {
          margin-bottom: 1.14rem; }
          .add-manual-record-modal .ant-modal-content .ant-modal-body .add-record-form .ant-form-item-control label {
            line-height: 1.57rem;
            font-size: 1.14rem;
            font-weight: 400; }
          .add-manual-record-modal .ant-modal-content .ant-modal-body .add-record-form .ant-form-item-control .ant-input, .add-manual-record-modal .ant-modal-content .ant-modal-body .add-record-form .ant-form-item-control .ant-select {
            margin-top: 0.71rem !important; }

.create-campaign-tab .ant-form-item-control {
  margin-bottom: 32px !important; }
  .create-campaign-tab .ant-form-item-control p.form-labels {
    margin: 0 0 5px 0 !important; }
  .create-campaign-tab .ant-form-item-control .ant-form-explain {
    margin-left: 12px; }

.create-campaign-tab .display-settings .ant-form-item-control, .create-campaign-tab .capture-settings .ant-form-item-control {
  margin: 24px 0 0 0 !important; }
  .create-campaign-tab .display-settings .ant-form-item-control p.form-labels, .create-campaign-tab .capture-settings .ant-form-item-control p.form-labels {
    font-size: 14px !important;
    font-weight: 500 !important; }

.create-campaign-tab .display-settings .url-field, .create-campaign-tab .capture-settings .url-field {
  margin-bottom: 10px !important;
  margin-top: 10px !important; }

.create-campaign-tab .ant-select-selection-selected-value {
  color: #727272;
  font-weight: 500; }

.create-campaign-tab .record-source {
  padding: 15px 17px;
  border: 1px solid #707070;
  border-radius: 3px;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-transition-duration: 0.3s;
          transition-duration: 0.3s;
  cursor: pointer; }
  .create-campaign-tab .record-source .record-source-content p {
    text-align: center;
    margin: 0; }
  .create-campaign-tab .record-source .record-source-content p.record-source-title {
    font-size: 13px;
    font-weight: 500;
    color: #151515;
    line-height: 22px;
    margin-bottom: 2px; }
  .create-campaign-tab .record-source .record-source-content p.record-source-desc {
    color: #595959;
    font-size: 12px;
    line-height: 15px;
    margin-bottom: 21px; }
  .create-campaign-tab .record-source .record-source-content .record-source-img img {
    height: 72px; }

.create-campaign-tab .record-source:hover {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.25); }

.create-campaign-tab .record-source.selected {
  border: 3px solid #36C626;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.25); }

.create-campaign-tab .csv-upload {
  min-width: 200px;
  position: relative; }
  .create-campaign-tab .csv-upload input[type=file] {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    height: 100%; }
  .create-campaign-tab .csv-upload .csv-upload-label {
    font-size: 1rem; }
    .create-campaign-tab .csv-upload .csv-upload-label i {
      font-size: 2rem;
      margin-bottom: 15px;
      margin-top: 15px; }

.alerts-row {
  display: -webkit-flex;
  display: flex;
  margin-bottom: 50px; }
  .alerts-row .alerts-row-column {
    -webkit-flex: 1 1;
            flex: 1 1; }
    .alerts-row .alerts-row-column .alert-card {
      padding: 25px 30px;
      border: 1px solid #707070;
      border-radius: 3px;
      height: 100%;
      cursor: pointer;
      -webkit-transition-duration: 0.3s;
              transition-duration: 0.3s; }
      .alerts-row .alerts-row-column .alert-card p {
        margin-bottom: 0; }
      .alerts-row .alerts-row-column .alert-card .alert-card-title {
        position: relative;
        font-size: 16px;
        line-height: 22px;
        padding-bottom: 21px;
        font-weight: 500;
        color: #151515; }
        .alerts-row .alerts-row-column .alert-card .alert-card-title .right {
          position: absolute;
          right: 0; }
      .alerts-row .alerts-row-column .alert-card .alert-card-description {
        margin-bottom: 21px;
        color: #595959; }
    .alerts-row .alerts-row-column .active {
      border: 1px solid #36C626; }
    .alerts-row .alerts-row-column .alert-card.has-error {
      border: 1px solid #FF2F2F;
      box-shadow: 0 0 8px #FF2F2F !important; }
      .alerts-row .alerts-row-column .alert-card.has-error .ant-alert {
        margin: 10px 0;
        width: 100%; }
      .alerts-row .alerts-row-column .alert-card.has-error .alert-card-title {
        color: #FF2F2F; }
    .alerts-row .alerts-row-column .coming-soon {
      pointer-events: none;
      -webkit-filter: grayscale(1);
              filter: grayscale(1);
      overflow: hidden; }
      .alerts-row .alerts-row-column .coming-soon .ant-switch {
        display: none; }
    .alerts-row .alerts-row-column .coming-soon:after {
      position: absolute;
      content: "Coming Soon";
      font-weight: bold;
      top: -15px;
      right: -55px;
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      -webkit-transform: rotate(45deg);
              transform: rotate(45deg);
      background: #02032a;
      color: white;
      padding: 0 20px; }
    .alerts-row .alerts-row-column .alert-card:hover {
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.25); }

.campaign-settings-tab .ant-input-group-wrapper input {
  height: 32px !important; }

.circle-ripple {
  background-color: #35ffc3;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  display: inline-block;
  vertical-align: top;
  -webkit-animation: live_preview_pulse 0.7s linear infinite;
          animation: live_preview_pulse 0.7s linear infinite; }

@-webkit-keyframes live_preview_pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(101, 255, 120, 0.3), 0 0 0 0.25em rgba(101, 255, 120, 0.3), 0 0 0 0.75em rgba(101, 255, 120, 0.3), 0 0 0 1.25em rgba(101, 255, 120, 0.3); }
  100% {
    box-shadow: 0 0 0 0.25em rgba(101, 255, 120, 0.3), 0 0 0 0.75em rgba(101, 255, 120, 0.3), 0 0 0 1.25em rgba(101, 255, 120, 0.3), 0 0 0 2em rgba(101, 255, 120, 0); } }

@keyframes live_preview_pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(101, 255, 120, 0.3), 0 0 0 0.25em rgba(101, 255, 120, 0.3), 0 0 0 0.75em rgba(101, 255, 120, 0.3), 0 0 0 1.25em rgba(101, 255, 120, 0.3); }
  100% {
    box-shadow: 0 0 0 0.25em rgba(101, 255, 120, 0.3), 0 0 0 0.75em rgba(101, 255, 120, 0.3), 0 0 0 1.25em rgba(101, 255, 120, 0.3), 0 0 0 2em rgba(101, 255, 120, 0); } }

.preview-sidebar {
  width: 35%;
  height: 100%;
  position: fixed;
  top: 64px;
  left: 0;
  background: rgba(2, 4, 41, 0.95);
  z-index: 1000;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  -webkit-transition: -webkit-transform 0.45s;
  transition: -webkit-transform 0.45s;
  transition: transform 0.45s;
  transition: transform 0.45s, -webkit-transform 0.45s; }
  .preview-sidebar .live-preview {
    position: absolute;
    bottom: 0;
    margin-bottom: 85px;
    width: 100%; }

.preview-sidebar.visible {
  -webkit-transform: translateX(0);
          transform: translateX(0); }

.callout-form-container {
  width: 65%;
  height: calc(100% - 64px);
  position: fixed;
  top: 64px;
  right: 0;
  z-index: 1000;
  background: white;
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
  -webkit-transition: -webkit-transform 0.45s 0.3s;
  transition: -webkit-transform 0.45s 0.3s;
  transition: transform 0.45s 0.3s;
  transition: transform 0.45s 0.3s, -webkit-transform 0.45s 0.3s; }
  .callout-form-container .ant-card {
    opacity: 0;
    -webkit-transition: opacity 0.3s;
    transition: opacity 0.3s; }
  .callout-form-container .template-select {
    width: 300px !important; }
    .callout-form-container .template-select .ant-select-selection--single {
      display: -webkit-flex;
      display: flex;
      height: auto; }
  .callout-form-container .ant-card {
    height: 100%; }
    .callout-form-container .ant-card .ant-card-body {
      height: 100%; }
      .callout-form-container .ant-card .ant-card-body .ant-tabs {
        height: 100%; }
        .callout-form-container .ant-card .ant-card-body .ant-tabs .ant-tabs-tab {
          border-right: 1px solid #BFBFBF; }
        .callout-form-container .ant-card .ant-card-body .ant-tabs .ant-tabs-tab-active {
          border-right: 6px solid #020429; }
        .callout-form-container .ant-card .ant-card-body .ant-tabs .ant-tabs-content {
          height: 100%;
          padding: 0; }
          .callout-form-container .ant-card .ant-card-body .ant-tabs .ant-tabs-content .tab-body > div {
            position: relative;
            height: 100%;
            overflow: scroll;
            scroll-behavior: smooth;
            padding: 0 60px; }
          .callout-form-container .ant-card .ant-card-body .ant-tabs .ant-tabs-content .tab-header {
            position: relative;
            padding-bottom: 15px;
            padding-left: 13px;
            border-bottom: 1px solid #979797;
            position: -webkit-sticky;
            position: sticky;
            padding-top: 30px;
            top: 0;
            background: white;
            z-index: 999; }
            .callout-form-container .ant-card .ant-card-body .ant-tabs .ant-tabs-content .tab-header h3 {
              font-size: 20px;
              line-height: 22px;
              font-weight: 400;
              display: inline-block;
              margin: 0;
              padding-top: 5px; }
            .callout-form-container .ant-card .ant-card-body .ant-tabs .ant-tabs-content .tab-header .right {
              position: -webkit-sticky;
              position: sticky;
              left: 100%; }
          .callout-form-container .ant-card .ant-card-body .ant-tabs .ant-tabs-content .tab-body {
            height: 100%;
            padding-top: 0; }
            .callout-form-container .ant-card .ant-card-body .ant-tabs .ant-tabs-content .tab-body .ant-form-item-control {
              margin: 16px 0; }
              .callout-form-container .ant-card .ant-card-body .ant-tabs .ant-tabs-content .tab-body .ant-form-item-control p.form-labels {
                margin-bottom: 10px;
                font-size: 16px;
                line-height: 22px;
                color: #464646; }
                .callout-form-container .ant-card .ant-card-body .ant-tabs .ant-tabs-content .tab-body .ant-form-item-control p.form-labels .switch-green {
                  margin-left: 16px;
                  margin-top: -3px; }

.callout-form-container.visible {
  -webkit-transform: translateX(0);
          transform: translateX(0);
  -webkit-transition: -webkit-transform 0.45s;
  transition: -webkit-transform 0.45s;
  transition: transform 0.45s;
  transition: transform 0.45s, -webkit-transform 0.45s; }
  .callout-form-container.visible .ant-card {
    -webkit-transition: opacity 0.3s 0.35s;
    transition: opacity 0.3s 0.35s;
    opacity: 1; }

.new-campaign input {
  height: 36px;
  font-size: 14px;
  line-height: 22px; }

.new-campaign .purple {
  display: inline-block;
  padding: 4px 12px;
  background-color: #E9E5FF; }

.new-campaign .btn-red {
  background: #FF2F2F;
  border-color: #FF2F2F;
  max-height: 36px;
  width: 115px;
  color: white;
  font-size: 14px; }

.new-campaign .btn-green {
  color: white;
  background: #36C626;
  border-color: #36C626;
  border-radius: 4px;
  width: 169px; }

.new-campaign .switch-green {
  background: #D7DBD7; }

.new-campaign .switch-green.ant-switch-checked {
  background: #36C626; }

.new-campaign .switch-green.ant-click-animating-node {
  background: 0 solid #36C626; }

.new-campaign .ant-form-item-control {
  margin: 16px 0; }
  .new-campaign .ant-form-item-control p.form-labels {
    margin: 0;
    font-size: 16px;
    line-height: 22px;
    color: #464646;
    font-weight: 500; }
    .new-campaign .ant-form-item-control p.form-labels .switch-green {
      margin-left: 16px;
      margin-top: -3px; }

.new-campaign .ant-form-item-control.disabled p.form-labels {
  color: #C7C7C7; }

.new-campaign .ant-card-body {
  padding: 6px; }

.new-campaign .ant-tabs-bar {
  width: 22.8%;
  border-right: 1px solid #BFBFBF; }
  .new-campaign .ant-tabs-bar .ant-tabs-nav-container .ant-tabs-tab {
    text-align: left;
    padding: 22px 30px;
    color: rgba(29, 29, 29, 0.65);
    font-size: 16px;
    margin-bottom: 0; }
  .new-campaign .ant-tabs-bar .ant-tabs-nav-container .ant-tabs-tab-active {
    background: #E9E5FF;
    font-weight: 600;
    color: #100E1A; }
  .new-campaign .ant-tabs-bar .ant-tabs-nav-container .ant-tabs-ink-bar {
    width: 6px; }

.new-campaign .ant-tabs-left-content {
  border-left: 1px solid #BFBFBF; }

.new-campaign .ant-tabs-content {
  overflow-x: scroll;
  padding: 30px 60px; }
  .new-campaign .ant-tabs-content .ant-tabs-tabpane {
    min-width: 678px; }
  .new-campaign .ant-tabs-content .campaign-tab .tab-header {
    position: relative;
    padding-bottom: 15px;
    padding-left: 13px;
    border-bottom: 1px solid #979797; }
    .new-campaign .ant-tabs-content .campaign-tab .tab-header h3 {
      font-size: 20px;
      line-height: 22px;
      font-weight: 400;
      display: inline-block;
      margin: 0;
      padding-top: 5px; }
    .new-campaign .ant-tabs-content .campaign-tab .tab-header .right {
      position: -webkit-sticky;
      position: sticky;
      left: 100%; }

.new-campaign .upload-actions {
  margin-top: 0.71rem;
  display: -webkit-flex;
  display: flex; }
  .new-campaign .upload-actions .upload-action {
    width: 7.14rem;
    height: 7.14rem;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 3px;
    color: rgba(0, 0, 0, 0.25);
    font-size: 1rem;
    text-align: center;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    margin-right: 0.5rem;
    cursor: pointer; }
    .new-campaign .upload-actions .upload-action .anticon {
      font-size: 2.25rem; }

.profile-settings .profile-form {
  color: #000; }
  .profile-settings .profile-form .column {
    padding: 1.5rem;
    padding-bottom: 0; }
  .profile-settings .profile-form .form-labels {
    margin: 0;
    font-size: 1.14rem;
    color: #151515; }
  .profile-settings .profile-form .button-container {
    text-align: right; }
    .profile-settings .profile-form .button-container .ant-btn-primary {
      background: #92c15f;
      border-color: #92c15f; }
    .profile-settings .profile-form .button-container .ant-btn-primary:hover, .profile-settings .profile-form .button-container .ant-btn-primary:focus {
      background: #69bd42;
      border-color: #69bd42; }
  @media (max-width: 576px) {
    .profile-settings .profile-form .button-container {
      text-align: center; } }
  .profile-settings .profile-form .ant-divider-horizontal {
    margin: 1rem 0; }

.activation-codes .ant-input {
  line-height: 32px; }

.manage-accounts .heading {
  color: rgba(0, 0, 0, 0.65);
  margin-bottom: 0;
  font-size: 1.28rem;
  font-weight: 300; }

.manage-accounts .ant-card {
  border-radius: 2px;
  padding: 0.57rem; }
  .manage-accounts .ant-card .ant-card-body {
    padding: 0;
    padding-bottom: 0.5rem; }
    .manage-accounts .ant-card .ant-card-body .actions-div .ant-btn {
      height: 2.5rem;
      margin-right: 0.714rem;
      padding: 0 0.714rem;
      background: white; }
      .manage-accounts .ant-card .ant-card-body .actions-div .ant-btn span {
        display: none; }
      .manage-accounts .ant-card .ant-card-body .actions-div .ant-btn svg {
        width: 1.14rem;
        height: 1.14rem; }
      .manage-accounts .ant-card .ant-card-body .actions-div .ant-btn .icon {
        width: 1.14rem;
        height: 1.14rem;
        overflow: hidden;
        -webkit-transition: width 0.1s !important;
        transition: width 0.1s !important; }
      .manage-accounts .ant-card .ant-card-body .actions-div .ant-btn .text {
        display: inline-block;
        height: 1.14rem;
        margin: 0;
        width: 0;
        overflow: hidden;
        -webkit-transition: width 0.1s !important;
        transition: width 0.1s !important; }
    .manage-accounts .ant-card .ant-card-body .actions-div .edit-btn {
      border: 1px solid #23202C; }
    .manage-accounts .ant-card .ant-card-body .actions-div .edit-btn:hover {
      background: #23202C;
      color: white; }
      .manage-accounts .ant-card .ant-card-body .actions-div .edit-btn:hover svg * {
        fill: white; }
    .manage-accounts .ant-card .ant-card-body .actions-div .delete-btn {
      border: 1px solid #F84B3D;
      background: white; }
    .manage-accounts .ant-card .ant-card-body .actions-div .delete-btn:hover {
      background: #F84B3D;
      color: white; }
      .manage-accounts .ant-card .ant-card-body .actions-div .delete-btn:hover svg * {
        fill: white; }
    .manage-accounts .ant-card .ant-card-body .actions-div .pause-btn {
      border: 1px solid #FF8200;
      background: white; }
      .manage-accounts .ant-card .ant-card-body .actions-div .pause-btn svg * {
        stroke: #FF8200;
        fill: #FF8200; }
    .manage-accounts .ant-card .ant-card-body .actions-div .pause-btn:hover {
      background: #FF8200;
      color: white; }
      .manage-accounts .ant-card .ant-card-body .actions-div .pause-btn:hover svg * {
        fill: white; }

.manage-accounts .ant-table th:first-of-type, .manage-accounts .ant-table td:first-of-type {
  padding-left: 3rem; }

.manage-accounts .ant-table th:last-of-type, .manage-accounts .ant-table td:last-of-type {
  padding-right: 3rem; }

.edit-views-modal .ant-modal-header {
  padding: 0;
  border-bottom: 0; }
  .edit-views-modal .ant-modal-header .ant-modal-title {
    text-align: center;
    font-size: 1.42rem;
    padding-top: 3.5rem; }

.edit-views-modal .ant-modal-body {
  padding: 1.71rem 5.3rem;
  text-align: center;
  font-size: 1.14rem; }
  .edit-views-modal .ant-modal-body .edit-view-modal-save-btn {
    margin-top: 1rem;
    background: #36C626;
    border-color: #36C626;
    color: white;
    border-radius: 3px; }

.settings .ant-tabs {
  background: white;
  border: 1px solid #DEDEDE;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.25);
  padding: 2rem 1rem; }
  .settings .ant-tabs .ant-tabs-tab {
    font-size: 1.14rem;
    color: #1D1D1D !important;
    font-weight: 300;
    text-align: left;
    padding-right: 5rem;
    padding-bottom: 1.25rem; }
  .settings .ant-tabs .ant-tabs-tab-active {
    color: #100E1A !important;
    font-weight: 500; }
  .settings .ant-tabs .ant-tabs-left-bar .ant-tabs-ink-bar {
    width: 5px;
    background: #100E1A; }

.account-settings {
  padding: 1.5rem 3rem; }
  .account-settings .heading {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: baseline;
            align-items: baseline;
    padding-left: 0.5rem;
    padding-right: 0.5rem; }
    .account-settings .heading .text {
      font-size: 1.43rem;
      font-weight: 400;
      color: #151515; }
    .account-settings .heading .ant-btn {
      background: #36C626;
      border-radius: 4px;
      border-color: #36C626 !important;
      font-size: 1rem;
      color: #fff;
      font-weight: 500;
      padding: 0.5rem 1.5rem;
      min-height: 2.5rem; }
  .account-settings .ant-divider {
    background: #979797;
    height: 2px; }
  .account-settings .input-container {
    margin-bottom: 1rem; }
    .account-settings .input-container .label {
      color: #151515;
      font-size: 1.14rem;
      margin-bottom: 0.5rem;
      font-weight: 400; }
    .account-settings .input-container .ant-input {
      border: 1px solid #979797;
      border-radius: 4px;
      font-size: 1rem;
      padding: 1rem; }
    .account-settings .input-container .ant-input-disabled {
      background: #F1F0F5; }
    .account-settings .input-container .ant-select-selection:hover {
      border: 1px solid #979797; }
    .account-settings .input-container .switch-text {
      font-size: 1rem;
      font-weight: 400;
      color: #8C8C8C; }
      .account-settings .input-container .switch-text .ant-switch {
        vertical-align: baseline;
        margin-left: 0.5rem; }
      .account-settings .input-container .switch-text .ant-switch-checked {
        background: #36C626; }
  .account-settings .col-1 .input-container {
    margin-bottom: 2rem; }
  .account-settings .domain_name_container {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    margin-bottom: 1rem; }
    .account-settings .domain_name_container .ant-input {
      -webkit-flex: 0.9 1;
              flex: 0.9 1; }
    .account-settings .domain_name_container .anticon {
      -webkit-flex: 0.3 1;
              flex: 0.3 1;
      cursor: pointer; }
      .account-settings .domain_name_container .anticon svg {
        width: 1.8rem;
        height: 1.8rem; }
  .account-settings .add-domain-btn {
    background: #FF5858;
    border-color: #FF5858;
    color: #fff;
    font-size: 1rem;
    font-weight: 500;
    border-radius: 7px;
    height: 2.5rem; }
    .account-settings .add-domain-btn .anticon + span {
      margin-left: 0.25rem; }
  .account-settings .add-domain-btn:hover,
  .account-settings .add-domain-btn:active,
  .account-settings .add-domain-btn:focus,
  .account-settings .add-domain-btn:focus-within {
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.25); }

.user-settings {
  padding: 1.5rem 3rem; }
  .user-settings .heading {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: baseline;
            align-items: baseline;
    padding-left: 0.5rem;
    padding-right: 0.5rem; }
    .user-settings .heading .text {
      font-size: 1.43rem;
      font-weight: 400;
      color: #151515; }
    .user-settings .heading .add-user-btn {
      background: #FF5858;
      border-color: #FF5858;
      color: #fff;
      font-size: 1rem;
      font-weight: 500;
      border-radius: 3px;
      height: 2.5rem; }
      .user-settings .heading .add-user-btn .anticon + span {
        margin-left: 0.25rem; }
    .user-settings .heading .add-user-btn:hover,
    .user-settings .heading .add-user-btn:active,
    .user-settings .heading .add-user-btn:focus,
    .user-settings .heading .add-user-btn:focus-within {
      box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.25); }
  .user-settings .ant-divider {
    background: #979797;
    height: 2px; }
  .user-settings .disclaimer {
    font-size: 1.14rem;
    color: #979797; }
  .user-settings .ant-table {
    margin-top: 1.5rem; }
    .user-settings .ant-table .user-name {
      text-transform: capitalize;
      margin-bottom: 0; }
    .user-settings .ant-table .role-tag {
      border-radius: 9px;
      font-size: 0.86rem;
      font-weight: 500;
      padding: 0 1rem; }
    .user-settings .ant-table .ant-table-tbody > tr > td .actions-div .ant-btn {
      height: 38px;
      width: 38px;
      font-size: 1rem;
      padding: 8px;
      margin-right: 0.714rem;
      font-weight: 500; }
    .user-settings .ant-table .ant-table-tbody > tr > td .actions-div .ant-btn:hover svg * {
      fill: white; }
    .user-settings .ant-table .ant-table-tbody > tr > td .actions-div .edit-btn {
      border: 1px solid #23202C; }
    .user-settings .ant-table .ant-table-tbody > tr > td .actions-div .edit-btn:hover {
      background: #23202C; }
    .user-settings .ant-table .ant-table-tbody > tr > td .actions-div .delete-btn {
      border: 1px solid #F84B3D;
      background: white; }
    .user-settings .ant-table .ant-table-tbody > tr > td .actions-div .delete-btn:hover {
      background: #F84B3D; }

.user-modal .ant-modal-content {
  border-radius: 3px;
  border: 1px solid #707070;
  box-shadow: none;
  padding: 2rem; }
  .user-modal .ant-modal-content .ant-modal-close {
    display: none; }
  .user-modal .ant-modal-content .ant-modal-header {
    border: none; }
    .user-modal .ant-modal-content .ant-modal-header .ant-modal-title.custom {
      font-size: 1.78rem;
      color: #020429;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-between;
              justify-content: space-between;
      -webkit-align-items: center;
              align-items: center; }
      .user-modal .ant-modal-content .ant-modal-header .ant-modal-title.custom svg {
        width: 1.71rem;
        height: 1.71rem;
        cursor: pointer; }
        .user-modal .ant-modal-content .ant-modal-header .ant-modal-title.custom svg * {
          color: rgba(0, 0, 0, 0.65); }
  .user-modal .ant-modal-content .ant-modal-body .ant-form .ant-input-group .label {
    font-size: 1.14rem;
    color: #464646;
    font-weight: 400;
    margin-bottom: 0.5rem; }
  .user-modal .ant-modal-content .ant-modal-body .ant-form .ant-input-group .ant-input {
    border-radius: 3px;
    border: 1px solid rgba(0, 0, 0, 0.15); }
  .user-modal .ant-modal-content .ant-modal-body .ant-form .ant-input-group .ant-select {
    width: 100%; }
  .user-modal .ant-modal-content .ant-modal-body .ant-form .ant-input-group .ant-select-dropdown-menu-item {
    border-bottom: 2px solid; }
  .user-modal .ant-modal-content .ant-modal-body .ant-form .submit-button {
    width: 100%;
    background: #36C626;
    color: #fff !important;
    border: none !important;
    outline: none !important;
    font-size: 1rem;
    font-weight: 600;
    border-radius: 4px;
    height: 2.86rem; }
  .user-modal .ant-modal-content .ant-modal-body .ant-form .submit-button:hover,
  .user-modal .ant-modal-content .ant-modal-body .ant-form .submit-button:active,
  .user-modal .ant-modal-content .ant-modal-body .ant-form .submit-button:focus,
  .user-modal .ant-modal-content .ant-modal-body .ant-form .submit-button:focus-within {
    background: rgba(54, 198, 38, 0.9); }

.option-span {
  margin-bottom: 0; }

.role-permissions-data .title {
  font-size: 0.71rem;
  color: #595959;
  font-weight: 500;
  margin-bottom: 0.5rem; }

.role-permissions-data p {
  font-size: 0.71rem;
  font-weight: 400;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }
  .role-permissions-data p svg {
    width: 0.93rem;
    height: 0.93rem;
    margin-right: 0.5rem; }

.translations {
  padding: 1.5rem 3rem; }
  .translations .heading {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: baseline;
            align-items: baseline;
    padding-left: 0.5rem;
    padding-right: 0.5rem; }
    .translations .heading .text {
      font-size: 1.43rem;
      font-weight: 400;
      color: #464646; }
    .translations .heading .add-translation-btn {
      background: #FF5858;
      border-color: #FF5858;
      color: #fff;
      font-size: 1rem;
      font-weight: 500;
      border-radius: 3px;
      height: 2.5rem; }
      .translations .heading .add-translation-btn .anticon + span {
        margin-left: 0.25rem; }
    .translations .heading .add-translation-btn:hover,
    .translations .heading .add-translation-btn:active,
    .translations .heading .add-translation-btn:focus,
    .translations .heading .add-translation-btn:focus-within {
      box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.25); }
  .translations .ant-divider {
    background: #979797;
    height: 2px; }
  .translations .disclaimer {
    font-size: 1.14rem;
    color: #979797; }
  .translations .ant-table {
    margin-top: 1.5rem; }
    .translations .ant-table .translation-name {
      text-transform: capitalize;
      margin-bottom: 0; }
    .translations .ant-table .role-tag {
      border-radius: 9px;
      font-size: 0.86rem;
      font-weight: 500;
      padding: 0 1rem; }
    .translations .ant-table .ant-table-tbody > tr > td .actions-div .ant-btn {
      height: 2.5rem;
      margin-right: 0.714rem;
      padding: 0 0.714rem 0 0.5rem;
      font-size: 1rem;
      font-weight: 500; }
      .translations .ant-table .ant-table-tbody > tr > td .actions-div .ant-btn svg {
        width: 1.14rem;
        height: 1.14rem; }
      .translations .ant-table .ant-table-tbody > tr > td .actions-div .ant-btn .icon {
        width: 1.14rem;
        height: 1.14rem;
        overflow: hidden;
        -webkit-transition: width 0.25s;
        transition: width 0.25s; }
    .translations .ant-table .ant-table-tbody > tr > td .actions-div .edit-btn {
      border: 1px solid #23202C; }
    .translations .ant-table .ant-table-tbody > tr > td .actions-div .edit-btn:hover {
      background: #23202C;
      color: white; }
      .translations .ant-table .ant-table-tbody > tr > td .actions-div .edit-btn:hover svg path {
        fill: #fff; }

.translations-modal .ant-modal-content {
  border-radius: 3px;
  border: 1px solid #707070;
  box-shadow: none;
  padding: 2rem; }
  .translations-modal .ant-modal-content .ant-modal-close {
    display: none; }
  .translations-modal .ant-modal-content .ant-modal-header {
    border: none; }
    .translations-modal .ant-modal-content .ant-modal-header .ant-modal-title.custom {
      font-size: 1.78rem;
      color: #020429;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-between;
              justify-content: space-between;
      -webkit-align-items: center;
              align-items: center; }
      .translations-modal .ant-modal-content .ant-modal-header .ant-modal-title.custom svg {
        width: 1.71rem;
        height: 1.71rem;
        cursor: pointer; }
        .translations-modal .ant-modal-content .ant-modal-header .ant-modal-title.custom svg * {
          color: rgba(0, 0, 0, 0.65); }
  .translations-modal .ant-modal-content .ant-modal-body {
    padding-top: 0; }
    .translations-modal .ant-modal-content .ant-modal-body .ant-form .ant-input-group .label {
      font-size: 1.14rem;
      color: #464646;
      font-weight: 400;
      margin-bottom: 0.5rem; }
    .translations-modal .ant-modal-content .ant-modal-body .ant-form .ant-input-group .ant-input {
      border-radius: 3px;
      border: 1px solid rgba(0, 0, 0, 0.15); }
    .translations-modal .ant-modal-content .ant-modal-body .ant-form .ant-input-group .ant-input.rtl {
      direction: rtl; }
    .translations-modal .ant-modal-content .ant-modal-body .ant-form .ant-input-group .ant-select {
      width: 100%; }
    .translations-modal .ant-modal-content .ant-modal-body .ant-form .ant-input-group .ant-select-dropdown-menu-item {
      border-bottom: 2px solid; }
    .translations-modal .ant-modal-content .ant-modal-body .ant-form .ant-input-group .ant-input-group-addon {
      padding: 0.5rem;
      background: #020429; }
      .translations-modal .ant-modal-content .ant-modal-body .ant-form .ant-input-group .ant-input-group-addon .insert-duraition {
        color: #fff;
        font-size: 0.71rem;
        cursor: pointer;
        width: 100%; }
    .translations-modal .ant-modal-content .ant-modal-body .ant-form .ant-input-group:first-of-type {
      color: #8C8C8C;
      font-size: 1rem;
      font-weight: 500; }
      .translations-modal .ant-modal-content .ant-modal-body .ant-form .ant-input-group:first-of-type .ant-switch-checked {
        background: #36C626; }
    .translations-modal .ant-modal-content .ant-modal-body .ant-form .submit-button {
      width: 100%;
      background: #36C626;
      color: #fff !important;
      border: none !important;
      outline: none !important;
      font-size: 1rem;
      font-weight: 600;
      border-radius: 4px;
      height: 2.86rem; }
    .translations-modal .ant-modal-content .ant-modal-body .ant-form .submit-button:hover,
    .translations-modal .ant-modal-content .ant-modal-body .ant-form .submit-button:active,
    .translations-modal .ant-modal-content .ant-modal-body .ant-form .submit-button:focus,
    .translations-modal .ant-modal-content .ant-modal-body .ant-form .submit-button:focus-within {
      background: rgba(54, 198, 38, 0.9); }

.option-span {
  margin-bottom: 0; }

.role-permissions-data .title {
  font-size: 0.71rem;
  color: #595959;
  font-weight: 500;
  margin-bottom: 0.5rem; }

.role-permissions-data p {
  font-size: 0.71rem;
  font-weight: 400;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }
  .role-permissions-data p svg {
    width: 0.93rem;
    height: 0.93rem;
    margin-right: 0.5rem; }

.pixel-settings {
  padding: 1.5rem 3rem; }
  .pixel-settings .heading {
    font-size: 1.43rem;
    font-weight: 400;
    color: #464646; }
  .pixel-settings .ant-divider {
    background: #979797;
    height: 2px; }
  .pixel-settings .disclaimer {
    font-size: 1.14rem;
    color: #979797; }
  .pixel-settings .disclaimer.disclaimer-bottom {
    margin-top: -1rem;
    font-style: italic;
    font-size: 1rem; }

.pixel-install-options .click-to-copy-btn {
  background: #36C626;
  border-color: #36C626;
  color: #fff !important;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 3px;
  height: 2.5rem;
  margin: 1rem 0 2rem 0;
  padding: 0.25rem 2rem 0rem 2rem; }
  .pixel-install-options .click-to-copy-btn .anticon svg {
    width: 1.25rem;
    height: 1.25rem; }
    .pixel-install-options .click-to-copy-btn .anticon svg * {
      fill: #fff !important; }
  .pixel-install-options .click-to-copy-btn .anticon + span {
    margin-left: 0.25rem; }

.pixel-install-options .click-to-copy-btn:hover,
.pixel-install-options .click-to-copy-btn:active,
.pixel-install-options .click-to-copy-btn:focus,
.pixel-install-options .click-to-copy-btn:focus-within {
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.25); }

.pixel-install-options .disclaimer {
  font-size: 1.14rem;
  color: #979797; }

.pixel-install-options .disclaimer.wordpress-text {
  margin-bottom: 0; }

.pixel-install-options textarea.ant-input {
  font-size: 1rem;
  padding: 0.75rem 1rem 1rem 1rem; }

.pixel-install-options .pixel-btn {
  background: #36C626;
  border-color: #36C626;
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 3px;
  height: 2.5rem;
  margin: 1rem 0 2rem 0;
  padding: 0rem 2rem 0rem 2rem; }
  .pixel-install-options .pixel-btn .anticon svg {
    width: 1.25rem;
    height: 1.25rem; }
    .pixel-install-options .pixel-btn .anticon svg * {
      fill: #fff !important; }
  .pixel-install-options .pixel-btn .anticon + span {
    margin-left: 0.25rem; }

.pixel-install-options .pixel-btn:hover,
.pixel-install-options .pixel-btn:active,
.pixel-install-options .pixel-btn:focus,
.pixel-install-options .pixel-btn:focus-within {
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.25); }

.pixel-install-options .pixel-btn.wordpess-button {
  padding-top: 0.25rem; }

.send-code-to-teammate .pixel-btn {
  background: #36C626;
  border-color: #36C626;
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 3px;
  height: 2.5rem;
  margin: 1rem 0 1rem 0;
  padding: 0rem 2rem 0rem 2rem; }
  .send-code-to-teammate .pixel-btn .anticon svg {
    width: 1.25rem;
    height: 1.25rem; }
    .send-code-to-teammate .pixel-btn .anticon svg * {
      fill: #fff !important; }
  .send-code-to-teammate .pixel-btn .anticon + span {
    margin-left: 0.25rem; }

.send-code-to-teammate .pixel-btn:hover,
.send-code-to-teammate .pixel-btn:active,
.send-code-to-teammate .pixel-btn:focus,
.send-code-to-teammate .pixel-btn:focus-within {
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.25); }

.send-code-to-teammate .ant-input {
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  font-size: 1rem;
  padding: 1rem;
  height: 2.5rem; }

.send-code-to-teammate .has-error .ant-input {
  border-color: #f5222d; }

.send-code-to-teammate .error-label {
  display: none; }

.send-code-to-teammate .has-error .error-label {
  display: block; }

.verify-pixel-settings {
  padding: 1.5rem 3rem; }
  .verify-pixel-settings .heading {
    font-size: 1.43rem;
    font-weight: 400;
    color: #464646; }
  .verify-pixel-settings .ant-divider {
    background: #979797;
    height: 2px; }

.pixel-verification-action .disclaimer {
  font-size: 1.14rem;
  color: #979797; }

.pixel-verification-action .disclaimer.disclaimer-bottom {
  margin-top: -1rem;
  font-style: italic;
  font-size: 1rem; }

.pixel-verification-action .pixel-btn {
  background: #36C626;
  border-color: #36C626;
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 3px;
  height: 2.5rem;
  margin: 1rem 0 0rem 0;
  padding: 0rem 2rem 0rem 2rem; }
  .pixel-verification-action .pixel-btn .anticon svg {
    width: 1.25rem;
    height: 1.25rem; }
    .pixel-verification-action .pixel-btn .anticon svg * {
      fill: #fff !important; }
  .pixel-verification-action .pixel-btn .anticon + span {
    margin-left: 0.25rem; }

.pixel-verification-action .pixel-btn:hover,
.pixel-verification-action .pixel-btn:active,
.pixel-verification-action .pixel-btn:focus,
.pixel-verification-action .pixel-btn:focus-within {
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.25); }

.pixel-verification-action .pixel-btn.wordpess-button {
  padding-top: 0.25rem; }

.pixel-verification-action .ant-input {
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  font-size: 1rem;
  padding: 1rem;
  height: 2.86rem;
  display: block; }

.pixel-verification-action .error-label {
  display: none; }

.pixel-verification-action .invalid .error-label {
  display: block; }

.pixel-verification-action .has-error .ant-input {
  border-color: #f5222d; }

.pixel-verification-action .ant-alert {
  position: absolute;
  top: 0px;
  border: 1px solid #FF1A2E;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.11);
  border-radius: 3px;
  width: 50%;
  left: 25%; }

.pixel-verification-action .skip {
  cursor: pointer;
  color: #8C8C8C;
  font-size: 1rem;
  font-weight: 600;
  margin-left: 1.57rem; }

.integrations {
  padding: 1.5rem 3rem; }
  .integrations .heading {
    font-size: 1.43rem;
    font-weight: 400;
    color: #151515; }
  .integrations .ant-divider {
    background: #979797;
    height: 2px; }
  .integrations .disclaimer {
    font-size: 1.14rem;
    color: #979797; }
  .integrations .disclaimer.disclaimer-bottom {
    margin-top: -1rem;
    font-style: italic;
    font-size: 1rem; }
  .integrations .ant-input {
    border-radius: 3px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    font-size: 1rem;
    padding: 1rem;
    width: 40%;
    display: block; }
  .integrations .integration-boxes {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-content: center;
            align-content: center;
    -webkit-flex-flow: row wrap;
            flex-flow: row wrap;
    margin-top: 2.5rem;
    margin-bottom: -2.5rem; }
    .integrations .integration-boxes .integration-box {
      border: 1px solid #707070;
      border-radius: 6px;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-justify-content: space-between;
              justify-content: space-between;
      -webkit-align-items: center;
              align-items: center;
      padding: 2rem;
      min-height: 10rem;
      margin-bottom: 2.5rem;
      cursor: pointer; }
      .integrations .integration-boxes .integration-box img {
        max-width: 8rem; }
      .integrations .integration-boxes .integration-box a, .integrations .integration-boxes .integration-box span {
        font-size: 1rem;
        font-weight: 500;
        color: #36C626;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: space-between;
                justify-content: space-between;
        -webkit-align-items: center;
                align-items: center; }
        .integrations .integration-boxes .integration-box a svg, .integrations .integration-boxes .integration-box span svg {
          width: 0.75rem;
          height: 0.75rem;
          margin-left: 0.25rem; }
          .integrations .integration-boxes .integration-box a svg *, .integrations .integration-boxes .integration-box span svg * {
            fill: #36C626; }
      .integrations .integration-boxes .integration-box a:hover,
      .integrations .integration-boxes .integration-box a:active,
      .integrations .integration-boxes .integration-box a:focus,
      .integrations .integration-boxes .integration-box a:focus-within {
        color: rgba(54, 198, 38, 0.9) !important; }
        .integrations .integration-boxes .integration-box a:hover svg *,
        .integrations .integration-boxes .integration-box a:active svg *,
        .integrations .integration-boxes .integration-box a:focus svg *,
        .integrations .integration-boxes .integration-box a:focus-within svg * {
          fill: rgba(54, 198, 38, 0.9) !important; }
    .integrations .integration-boxes .integration-box.active {
      padding-left: 2.5rem;
      padding-right: 2.5rem; }

.integration-modal .ant-modal-content {
  border-radius: 3px;
  border: 1px solid #707070;
  box-shadow: none;
  padding: 2rem; }
  .integration-modal .ant-modal-content .ant-modal-close {
    display: none; }
  .integration-modal .ant-modal-content .ant-modal-header {
    border: none; }
    .integration-modal .ant-modal-content .ant-modal-header .ant-modal-title.custom {
      font-size: 1.78rem;
      color: #020429;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-between;
              justify-content: space-between;
      -webkit-align-items: center;
              align-items: center; }
      .integration-modal .ant-modal-content .ant-modal-header .ant-modal-title.custom svg {
        width: 1.71rem;
        height: 1.71rem;
        cursor: pointer; }
        .integration-modal .ant-modal-content .ant-modal-header .ant-modal-title.custom svg * {
          color: rgba(0, 0, 0, 0.65); }
  .integration-modal .ant-modal-content .ant-modal-body .ant-form .ant-input-group .label {
    font-size: 1.14rem;
    color: #464646;
    font-weight: 400;
    margin-bottom: 0.5rem; }
  .integration-modal .ant-modal-content .ant-modal-body .ant-form .ant-input-group .ant-input {
    border-radius: 3px;
    border: 1px solid rgba(0, 0, 0, 0.15); }
  .integration-modal .ant-modal-content .ant-modal-body .ant-form .ant-input-group .ant-select {
    width: 100%; }
  .integration-modal .ant-modal-content .ant-modal-body .ant-form .ant-input-group .ant-select-dropdown-menu-item {
    border-bottom: 2px solid; }
  .integration-modal .ant-modal-content .ant-modal-body .ant-form .submit-button {
    width: 100%;
    background: #36C626;
    color: #fff !important;
    border: none !important;
    outline: none !important;
    font-size: 1rem;
    font-weight: 600;
    border-radius: 4px;
    height: 2.86rem; }
  .integration-modal .ant-modal-content .ant-modal-body .ant-form .submit-button:hover,
  .integration-modal .ant-modal-content .ant-modal-body .ant-form .submit-button:active,
  .integration-modal .ant-modal-content .ant-modal-body .ant-form .submit-button:focus,
  .integration-modal .ant-modal-content .ant-modal-body .ant-form .submit-button:focus-within {
    background: rgba(54, 198, 38, 0.9); }

.billing .ant-tabs {
  background: white;
  border: 1px solid #DEDEDE;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.25);
  padding: 2rem 1rem; }
  .billing .ant-tabs .ant-tabs-tab {
    font-size: 1.14rem;
    color: #1D1D1D !important;
    font-weight: 300;
    text-align: left;
    padding-right: 5rem;
    padding-bottom: 1.25rem; }
  .billing .ant-tabs .ant-tabs-tab-active {
    color: #100E1A !important;
    font-weight: 500; }
  .billing .ant-tabs .ant-tabs-left-bar .ant-tabs-ink-bar {
    width: 5px;
    background: #100E1A; }

.billing .label {
  font-size: 1.14rem;
  color: #151515;
  margin-bottom: 1.15rem;
  font-weight: 400; }

.billing .value {
  font-size: 1rem;
  font-weight: 400;
  color: #979797;
  margin-bottom: 2rem; }

.billing-plan {
  padding: 1.5rem 3rem; }
  .billing-plan .heading {
    font-size: 1.43rem;
    font-weight: 400;
    color: #464646; }
  .billing-plan .ant-divider {
    background: #979797;
    height: 2px; }
  .billing-plan .plan-btn {
    font-size: 1rem;
    font-weight: 500;
    border-radius: 4px;
    height: 2.5rem;
    margin: 1rem 0 2rem 0;
    padding: 0rem 2rem 0rem 2rem; }
  .billing-plan .plan-btn:hover,
  .billing-plan .plan-btn:active,
  .billing-plan .plan-btn:focus,
  .billing-plan .plan-btn:focus-within {
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.25); }
  .billing-plan .plan-btn.success {
    background: #36C626;
    border-color: #36C626;
    color: #fff;
    margin-right: 1rem; }
  .billing-plan .plan-btn.cancel {
    background: #fff;
    border-color: #979797;
    border-width: 2px;
    color: #979797;
    padding: 0rem 1rem 0rem 1rem; }

.billing-subscription-details {
  padding: 1.5rem 3rem; }
  .billing-subscription-details .heading {
    font-size: 1.43rem;
    font-weight: 400;
    color: #464646; }
  .billing-subscription-details .ant-divider {
    background: #979797;
    height: 2px; }

.billing-history {
  padding: 1rem 3rem 1.5rem 3rem; }
  .billing-history .ant-table-title {
    padding-top: 0; }
    .billing-history .ant-table-title .title {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
              flex-direction: row;
      -webkit-justify-content: space-between;
              justify-content: space-between;
      -webkit-align-items: center;
              align-items: center; }
      .billing-history .ant-table-title .title .heading {
        font-size: 1.43rem;
        font-weight: 400;
        color: #464646; }
      .billing-history .ant-table-title .title .search-div {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
                flex-direction: row;
        -webkit-justify-content: space-between;
                justify-content: space-between;
        -webkit-align-items: center;
                align-items: center;
        font-size: 1rem;
        color: rgba(0, 0, 0, 0.85); }
        .billing-history .ant-table-title .title .search-div .ant-input {
          border: 1px solid rgba(0, 0, 0, 0.15);
          border-radius: 4px;
          font-size: 1rem;
          padding: 1rem;
          margin-left: 1rem; }
  .billing-history .ant-table-content {
    border-top: 1px solid #979797; }
    .billing-history .ant-table-content .ant-table-tbody {
      color: rgba(0, 0, 0, 0.45); }
      .billing-history .ant-table-content .ant-table-tbody .plan-name {
        color: rgba(0, 0, 0, 0.65);
        font-weight: 500; }
      .billing-history .ant-table-content .ant-table-tbody .download-button {
        border: 1.2px solid #100E1A;
        border-radius: 4px;
        height: 2.75rem;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-transition-duration: 0.3s;
                transition-duration: 0.3s; }
        .billing-history .ant-table-content .ant-table-tbody .download-button svg {
          width: 1.25rem;
          height: 1.25rem; }
          .billing-history .ant-table-content .ant-table-tbody .download-button svg * {
            fill: #100E1A; }
      .billing-history .ant-table-content .ant-table-tbody .download-button:hover {
        background: #100E1A; }
        .billing-history .ant-table-content .ant-table-tbody .download-button:hover svg * {
          fill: white; }
  .billing-history .ant-divider {
    background: #979797;
    height: 2px; }

.onboarding-container {
  background: url(/static/media/Onboarding.41fd81a9.svg);
  background-size: cover;
  background-position-y: bottom;
  background-attachment: fixed; }
  .onboarding-container .onboarding {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    padding-top: 4.143rem; }
    .onboarding-container .onboarding .logo {
      width: 14.57rem;
      height: 3.71rem;
      margin-bottom: 1.643rem; }
    .onboarding-container .onboarding .progress-bar {
      position: relative;
      width: 22.21rem;
      height: 1.43rem;
      border-radius: 10px;
      background: #EDEDED;
      margin-bottom: 4.143rem; }
    .onboarding-container .onboarding .progress-bar::after {
      content: '';
      position: absolute;
      left: 0;
      background: #36C626;
      border-radius: 10px;
      width: 8rem;
      height: 1.43rem;
      z-index: 9; }
    .onboarding-container .onboarding .title {
      color: #020429;
      font-size: 1.43rem;
      font-weight: 500;
      margin-bottom: 0.43rem; }
    .onboarding-container .onboarding .sub-title {
      font-size: 1.286rem;
      color: #8C8C8C;
      font-weight: 400;
      margin-bottom: 2rem; }

.ant-modal.onboarding-modal .ant-modal-content {
  border-radius: 3px; }
  .ant-modal.onboarding-modal .ant-modal-content .ant-modal-body {
    padding: 3.36rem 3.786rem;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center; }
    .ant-modal.onboarding-modal .ant-modal-content .ant-modal-body .heading {
      font-size: 1.714rem;
      font-weight: 500;
      margin-bottom: 1.93rem;
      color: rgba(0, 0, 0, 0.85); }
    .ant-modal.onboarding-modal .ant-modal-content .ant-modal-body .ant-btn {
      background: #36C626;
      color: #fff;
      outline: none;
      font-size: 1rem;
      font-weight: 600;
      width: 20.71rem;
      height: 2.86rem;
      outline: none !important; }
      .ant-modal.onboarding-modal .ant-modal-content .ant-modal-body .ant-btn .anticon svg {
        width: 0.968rem;
        height: 0.82rem;
        margin-bottom: 0;
        vertical-align: text-top; }
        .ant-modal.onboarding-modal .ant-modal-content .ant-modal-body .ant-btn .anticon svg * {
          fill: #fff; }
    .ant-modal.onboarding-modal .ant-modal-content .ant-modal-body .ant-btn:hover,
    .ant-modal.onboarding-modal .ant-modal-content .ant-modal-body .ant-btn:active,
    .ant-modal.onboarding-modal .ant-modal-content .ant-modal-body .ant-btn:focus,
    .ant-modal.onboarding-modal .ant-modal-content .ant-modal-body .ant-btn:focus-within {
      border-color: #36C626; }

.ant-modal.onboarding-modal.welcome-modal .ant-modal-content .ant-modal-body svg {
  width: 10rem;
  height: 10rem;
  -webkit-margin-after: 1.71rem;
          margin-block-end: 1.71rem; }

.ant-modal.onboarding-modal.welcome-modal .ant-modal-content .ant-modal-body .heading {
  color: #020429;
  font-size: 1.786rem;
  font-weight: 600; }

.ant-modal.onboarding-modal.welcome-modal .ant-modal-content .ant-modal-body p.para {
  font-size: 1.14rem;
  text-align: center;
  font-weight: 400;
  margin-bottom: 1rem;
  color: #8C8C8C; }
  .ant-modal.onboarding-modal.welcome-modal .ant-modal-content .ant-modal-body p.para img {
    width: 1.286rem;
    vertical-align: text-bottom; }
  .ant-modal.onboarding-modal.welcome-modal .ant-modal-content .ant-modal-body p.para .name {
    text-transform: capitalize; }

.ant-modal.onboarding-modal.welcome-modal .ant-modal-content .ant-modal-body p.para-3 {
  color: #464646; }

.ant-modal.onboarding-modal.welcome-modal .ant-modal-content .ant-modal-body .below-button {
  font-size: 0.86rem;
  color: #8C8C8C;
  font-weight: 400;
  margin-top: 0.286rem; }

.ant-modal.onboarding-modal.onboarding-success-modal .ant-modal-content .ant-modal-body,
.ant-modal.onboarding-modal.onboarding-failure-modal .ant-modal-content .ant-modal-body {
  padding: 3.214rem 3.357rem; }
  .ant-modal.onboarding-modal.onboarding-success-modal .ant-modal-content .ant-modal-body svg,
  .ant-modal.onboarding-modal.onboarding-failure-modal .ant-modal-content .ant-modal-body svg {
    width: 5.14rem;
    height: 5.14rem;
    margin-bottom: 2.21rem; }
  .ant-modal.onboarding-modal.onboarding-success-modal .ant-modal-content .ant-modal-body .disclaimer,
  .ant-modal.onboarding-modal.onboarding-failure-modal .ant-modal-content .ant-modal-body .disclaimer {
    font-size: 1rem;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.45);
    text-align: center;
    margin-bottom: 0; }
  .ant-modal.onboarding-modal.onboarding-success-modal .ant-modal-content .ant-modal-body .below-disclaimer,
  .ant-modal.onboarding-modal.onboarding-failure-modal .ant-modal-content .ant-modal-body .below-disclaimer {
    margin-top: 2.07rem; }

.onboarding-form .ant-form-item {
  margin-bottom: 1.93rem; }
  .onboarding-form .ant-form-item .input-label {
    font-size: 1.143rem;
    margin-bottom: 0.5rem;
    color: #151515;
    font-weight: 400; }
  .onboarding-form .ant-form-item .ant-input {
    border: 1px solid #979797;
    width: 19.138rem;
    height: 2.286rem;
    font-size: 1rem;
    font-weight: 400; }
  .onboarding-form .ant-form-item .ant-btn {
    width: 19.143rem;
    height: 2.857rem;
    border: none !important;
    background: #36C626;
    color: #fff;
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 2rem; }
    .onboarding-form .ant-form-item .ant-btn svg {
      width: 0.97rem;
      height: 0.82rem;
      vertical-align: text-top; }
      .onboarding-form .ant-form-item .ant-btn svg * {
        fill: #fff; }

.onboarding-form .ant-form-item:last-of-type {
  margin-bottom: 0; }

.onboarding-tooltip .ant-tooltip-arrow {
  top: 50%;
  margin-top: -10px;
  left: 0px;
  border-width: 10px 8.16px 10px 0;
  border-right-color: rgba(0, 0, 0, 0.75); }

.onboarding-tooltip .ant-tooltip-inner {
  padding: 0.643rem 1.286rem;
  font-size: 0.857rem;
  background: #3C3C3C;
  color: #FFFFFF;
  width: 15.642rem; }

.steps .left {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  padding: 1.14rem 0 0 0;
  position: relative; }
  .steps .left .logo {
    width: 15.57rem;
    margin-bottom: 1rem; }
  .steps .left .title {
    color: #020429;
    font-weight: 500;
    font-size: 1.43rem;
    margin-bottom: 0; }
  .steps .left .sub-title {
    font-weight: 400;
    color: #8C8C8C;
    font-size: 1.286rem;
    margin-bottom: 7.21rem;
    text-align: center; }
    .steps .left .sub-title u {
      text-decoration: underline; }
  .steps .left .pixel-install-options-container .sub-title {
    margin-bottom: 1.64rem; }
  .steps .left .pixel-install-options-container .pixel-install-options .pixel-btn.wordpess-button {
    margin-bottom: 0; }
  .steps .left .pixel-install-options-container .pixel-install-options .click-to-copy-btn {
    margin-bottom: 1rem; }
  .steps .left .pixel-install-options-container .pixel-install-options .or {
    font-size: 1rem;
    margin-bottom: 0.5rem; }
  .steps .left .pixel-verify-action-container .sub-title {
    margin-bottom: 7.5rem; }
  .steps .left .pixel-verify-action-container .ant-card {
    margin-bottom: 3rem; }
  .steps .left .user-profile-form-container .sub-title {
    margin-bottom: 1rem; }
  .steps .left .user-profile-form-container .ant-card {
    width: 41.86rem; }
    .steps .left .user-profile-form-container .ant-card .ant-card-body {
      padding-bottom: 0; }
  .steps .left .ant-card {
    width: 50rem;
    max-width: 100%;
    margin-bottom: 1rem;
    border: 1px solid #DEDEDE;
    border-radius: 3px;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.11); }
    .steps .left .ant-card .ant-card-body {
      padding: 1.64rem 2.36rem; }
  .steps .left .continue-btn {
    display: block;
    margin: auto;
    color: #fff;
    font-size: 1rem;
    font-weight: 600;
    border: none !important;
    outline: none !important;
    background: #36C626;
    border-radius: 3px;
    width: 9.286rem;
    height: 2.5rem;
    margin-bottom: 2rem; }
    .steps .left .continue-btn svg {
      width: 1.2rem;
      height: 1.2rem;
      vertical-align: text-bottom;
      margin-left: 0.286rem; }
      .steps .left .continue-btn svg * {
        fill: #fff; }

.steps .right {
  background: -webkit-linear-gradient(top, #020429, #0F1130);
  background: linear-gradient(to bottom, #020429, #0F1130);
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  padding: 12.14rem 2rem 4.08rem 2rem;
  position: fixed;
  top: 0;
  right: 0; }
  .steps .right .back-to-dashboard {
    color: #FFFFFF;
    font-size: 1.43rem;
    font-weight: 500;
    margin-bottom: 3.57rem; }
    .steps .right .back-to-dashboard svg {
      width: 1.71rem;
      height: 1.71rem;
      vertical-align: text-bottom;
      margin-left: 0.57rem; }
      .steps .right .back-to-dashboard svg * {
        fill: #fff; }
  .steps .right ul {
    list-style-type: none;
    padding: 0; }
    .steps .right ul li {
      font-size: 1.286rem;
      font-weight: 500;
      color: #fff;
      opacity: 0.31;
      position: relative;
      margin-bottom: 2.143rem; }
    .steps .right ul li::before {
      content: '';
      position: absolute;
      background: #50516B;
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
      top: 0.5rem;
      left: -2.43rem; }
    .steps .right ul li.done::before {
      content: '\2713';
      color: #fff;
      font-size: 0.75rem;
      padding: 0 0.15rem; }
    .steps .right ul li.active {
      opacity: 1; }
    .steps .right ul li.active::before {
      background: #020429;
      border: 2px solid #fff; }
    .steps .right ul li::after {
      content: '';
      position: absolute;
      bottom: 1.3rem;
      height: 3.1rem;
      width: 4px;
      left: -2.05rem;
      background: #50516B; }
    .steps .right ul li.active::after {
      opacity: 0.3; }
    .steps .right ul li:last-of-type {
      margin-bottom: 15.5rem; }
    .steps .right ul li:first-of-type::after {
      display: none; }
    .steps .right ul li:hover {
      cursor: pointer;
      opacity: 1; }
    .steps .right ul li.done:hover {
      cursor: auto;
      opacity: 0.3; }
  .steps .right .bottom {
    text-align: center;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    padding-bottom: 1rem; }
    .steps .right .bottom .text {
      color: #BBBCCB;
      font-size: 1.143rem;
      font-weight: 400; }
    .steps .right .bottom svg {
      width: 7.86rem;
      max-width: 100%; }

.pixel-actions {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center; }
  .pixel-actions .action {
    border: 1px solid #DEDEDE;
    background: #fff;
    border-radius: 3px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    padding: 2.21rem 1.43rem;
    margin-bottom: 7rem; }
    .pixel-actions .action svg {
      width: 4.286rem;
      height: 4.286rem; }
    .pixel-actions .action .heading {
      font-size: 1.286rem;
      font-weight: 500;
      color: #585858;
      margin-bottom: 0; }
    .pixel-actions .action .sub-heading {
      color: #8C8C8C;
      font-weight: 400;
      font-size: 0.86rem; }
    .pixel-actions .action .tag-white {
      display: none; }
  .pixel-actions .left-action {
    margin-right: 3.21rem; }
  .pixel-actions .action:hover {
    cursor: pointer;
    background: #FF2F2F; }
    .pixel-actions .action:hover .heading, .pixel-actions .action:hover .sub-heading {
      color: #fff; }
  .pixel-actions .left-action:hover svg {
    display: none; }
  .pixel-actions .left-action:hover .tag-white {
    display: inline-block; }
  .pixel-actions .right-action:hover svg * {
    fill: #fff !important;
    stroke: #fff; }

.send-to-teammate-modal {
  text-align: center; }
  .send-to-teammate-modal .ant-modal-body {
    padding: 2rem 4rem; }
    .send-to-teammate-modal .ant-modal-body .close-modal {
      position: absolute;
      top: 2rem;
      right: 2.14rem;
      width: 1.71rem;
      height: 1.71rem;
      cursor: pointer; }
      .send-to-teammate-modal .ant-modal-body .close-modal * {
        fill: #000;
        stroke-width: 0; }
    .send-to-teammate-modal .ant-modal-body svg {
      width: 5.59rem;
      height: 5.43rem;
      max-width: 100%;
      margin-bottom: 0.76rem; }
      .send-to-teammate-modal .ant-modal-body svg * {
        fill: #FF2F2F;
        stroke: #FF2F2F; }
    .send-to-teammate-modal .ant-modal-body .title {
      font-size: 1.43rem;
      font-weight: 500;
      color: #020429;
      margin-bottom: 0.93rem; }
    .send-to-teammate-modal .ant-modal-body .description {
      font-size: 1.143rem;
      font-weight: 400;
      color: #979797; }

.success-modal {
  text-align: center; }
  .success-modal .ant-modal-body {
    padding: 4rem 4rem 3rem 4rem; }
    .success-modal .ant-modal-body .close-modal {
      position: absolute;
      top: 2rem;
      right: 2.14rem;
      width: 1.71rem;
      height: 1.71rem;
      cursor: pointer; }
      .success-modal .ant-modal-body .close-modal * {
        fill: #000;
        stroke-width: 0; }
    .success-modal .ant-modal-body img {
      margin-bottom: 0.47rem; }
    .success-modal .ant-modal-body .title {
      font-size: 1.43rem;
      font-weight: 500;
      color: #020429;
      margin-bottom: 0.93rem; }
    .success-modal .ant-modal-body .description {
      font-size: 1.143rem;
      font-weight: 400;
      color: #979797;
      margin-bottom: 1rem; }
    .success-modal .ant-modal-body .ant-btn-default {
      border-radius: 3;
      background: #36C626;
      color: #fff;
      height: 2.5rem;
      font-size: 1rem;
      font-weight: 600;
      padding: 0 1.71rem;
      margin: 0;
      border: none !important;
      outline: none !important; }
      .success-modal .ant-modal-body .ant-btn-default svg {
        width: 1rem;
        height: 1rem; }
        .success-modal .ant-modal-body .ant-btn-default svg * {
          fill: #fff;
          stroke-width: 0; }

.user-profile-form .ant-form-item {
  margin-bottom: 1rem; }
  .user-profile-form .ant-form-item .label {
    font-size: 1rem;
    color: #595959; }
  .user-profile-form .ant-form-item .ant-input, .user-profile-form .ant-form-item .ant-select-selection {
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    height: 2.5rem;
    font-size: 1rem; }
  .user-profile-form .ant-form-item .ant-select-selection__rendered {
    height: 2.5rem;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center; }
  .user-profile-form .ant-form-item .ant-btn-default {
    border-radius: 3;
    background: #36C626;
    color: #fff;
    height: 2.5rem;
    font-size: 1rem;
    font-weight: 600;
    padding: 0 1.71rem;
    margin: 0;
    border: none !important;
    outline: none !important; }
    .user-profile-form .ant-form-item .ant-btn-default svg {
      width: 1rem;
      height: 1rem; }
      .user-profile-form .ant-form-item .ant-btn-default svg * {
        fill: #fff;
        stroke-width: 0; }

.campaign-report .ant-card {
  background: #fff;
  border-radius: 2px;
  border: none;
  box-shadow: none;
  margin-bottom: 40px; }
  .campaign-report .ant-card .ant-card-body {
    padding: 17px 0; }
    .campaign-report .ant-card .ant-card-body .ant-tabs-bar {
      padding: 0 32px; }

.campaign-report .hourwise-stats-card .ant-card-body {
  padding-bottom: 0; }

.campaign-report .pie-chart-cards {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between; }
  .campaign-report .pie-chart-cards .ant-card .ant-card-head {
    border-bottom: 0; }
    .campaign-report .pie-chart-cards .ant-card .ant-card-head .ant-card-head-title {
      text-align: center;
      color: rgba(0, 0, 0, 0.85);
      font-weight: 500;
      font-size: 1.21rem;
      padding: 1.43rem 0; }
  .campaign-report .pie-chart-cards .ant-card .ant-card-body {
    padding-top: 0; }
  .campaign-report .pie-chart-cards .ant-card .ant-tabs-nav-scroll {
    text-align: center; }
    .campaign-report .pie-chart-cards .ant-card .ant-tabs-nav-scroll .ant-tabs-nav {
      border: 1px solid #D9D9D9;
      margin-bottom: 1.19rem;
      border-radius: 4px; }
      .campaign-report .pie-chart-cards .ant-card .ant-tabs-nav-scroll .ant-tabs-nav .ant-tabs-tab {
        padding: 0.48rem 1.71rem;
        margin: 0;
        font-size: 1.07rem;
        color: #595959; }
      .campaign-report .pie-chart-cards .ant-card .ant-tabs-nav-scroll .ant-tabs-nav .ant-tabs-tab-active {
        color: #fff !important;
        background: #1890FF;
        border-color: #1890FF;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px; }
      .campaign-report .pie-chart-cards .ant-card .ant-tabs-nav-scroll .ant-tabs-nav .ant-tabs-tab:last-of-type.ant-tabs-tab-active {
        border-radius: 0;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px; }
      .campaign-report .pie-chart-cards .ant-card .ant-tabs-nav-scroll .ant-tabs-nav .ant-tabs-ink-bar {
        display: none !important; }

.hourwise-stats-tabs .g2-tooltip-list-item::before {
  content: "\2022";
  color: #2D6F26;
  font-weight: 600;
  font-size: 1.5rem;
  border-radius: 50%;
  margin-right: 0.25rem; }

.daywise-stats-tabs .g2-tooltip-list-item::before {
  content: "\2022";
  color: #8E3030;
  font-weight: 600;
  font-size: 1.5rem;
  border-radius: 50%;
  margin-right: 0.25rem; }


.pieslice-container {
  padding-top: 2.092rem; }
  .pieslice-container .table {
    padding: 0 3rem;
    margin-top: 3rem;
    overflow: scroll;
    max-height: 20rem;
    margin-bottom: 3.17rem; }
    .pieslice-container .table .heading, .pieslice-container .table .data {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-between;
              justify-content: space-between; }
    .pieslice-container .table .heading {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      background: white; }
    .pieslice-container .table .heading p,
    .pieslice-container .table .data p {
      font-size: 1.071rem;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.85);
      text-transform: capitalize;
      margin-bottom: 1.68rem; }
    .pieslice-container .table .data p {
      font-weight: 400;
      color: rgba(0, 0, 0, 0.65);
      margin-bottom: 1.52rem; }



.countrywise-stats-container .ant-tabs-tabpane {
  padding: 0 0.786rem;
  margin-bottom: 2.21rem; }

.countrywise-stats-container .table {
  padding: 0 0.786rem; }
  .countrywise-stats-container .table .content {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    border-bottom: 1px solid #E8E8E8; }
    .countrywise-stats-container .table .content p {
      -webkit-flex: 0.3 1;
              flex: 0.3 1;
      padding-left: 50px;
      font-size: 1.07rem;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.65);
      margin-bottom: 1.73rem;
      padding-top: 1.486rem; }
    .countrywise-stats-container .table .content p:last-of-type {
      padding-left: 0px;
      padding-right: 50px;
      text-align: right; }
    .countrywise-stats-container .table .content p.middle {
      text-align: center; }
  .countrywise-stats-container .table .content:first-of-type {
    background: #FAFAFA;
    padding-top: 1rem;
    border-bottom: 0;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px; }
    .countrywise-stats-container .table .content:first-of-type p {
      font-size: 1rem;
      font-weight: 500;
      margin-bottom: 1.36rem;
      padding-top: 0; }
  .countrywise-stats-container .table .content:last-of-type {
    border-bottom: 0; }

