.user-reviews {
    padding-top: 0;

    .heading {
        color: rgba(0, 0, 0, 0.65);
        margin-bottom: 0;
        font-size: 1.28rem;
        font-weight: 300;
    }

    .ant-card {
        border-radius: 2px;

        .ant-card-body {
            padding: 0;
            padding-bottom: 0.5rem;

            .ant-table {
                .ant-table-tbody > tr > td {
                    .review-preview {
                        max-width: 300px;
                    }
                    .actions-div {
                        .ant-btn {
                            height: 2.5rem;
                            margin-right: 0.714rem;
                            padding: 0 0.714rem;
                            span {
                                display: none;
                            }
                            svg {
                                width: 1.14rem;
                                height: 1.14rem;
                            }
                            .icon {
                                width: 1.14rem;
                                height: 1.14rem;
                                overflow: hidden;
                                transition: width 0.25s;
                            }
                            .text {
                                display: inline-block;
                                height: 1.14rem;
                                margin: 0;
                                width: 0;
                                overflow: hidden;
                                transition: width 0.25s;
                            }
                        }
                        .edit-btn {
                            border: 1px solid #23202C;
                        }
                        .edit-btn:hover {
                            background: #23202C;
                            color: white;
                            svg * {
                                fill: white;
                            }
                        }
                        .delete-btn {
                            border: 1px solid #F84B3D;
                            background: white;
                        }
                        .delete-btn:hover {
                            background: #F84B3D;
                            color: white;
                            svg * {
                                fill: white;
                            }
                        }
                    }
                }
            }
        }
    }
}
.review-preview {
    margin: 0;
    .review-previewimage {
        width: 48px;
        height: 48px;
        display: inline-block;
        // border: 1px solid #979797;
        background: #FAFAFA;
        border-radius: 4px;
        vertical-align: middle;
    }
    .review-content {
        display: inline-block;
        vertical-align: middle;
        padding-left: 10px;
        width: calc(100% - 58px);
        .reviewer_name {
            margin: 0;
            width: 100%;
        }
        .review_text {
            width: 100%;
            color: rgba(0,0,0,0.45);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            position: relative;
            margin-bottom: 0;
        }
        .review_text:before,.review_text:after {
            content: '"'
        }
    }
}
.ant-select-selection__choice__content {
    .review_text {
        display: none;
    }
    .review_stars {
        display: none;
    }
    .review-previewimage {
        width: 15px;
        height: 15px;
    }
}