.app-footer {
  position: sticky;
  top: 100%;
  padding: 1.6rem 0;
  ul {
    list-style: none;
    padding: 0;
    li {
      display: inline-block;
      vertical-align: middle;
      margin-right: 1.57rem;
      a {
        color: #979797 !important;
        font-weight: 500;
      }
      img {
        max-height: 60px;
      }
    }
  }
}